import React, { useState } from "react";
import { BASE_URL } from "../constants";

const ImagePreview = ({ file, imageValue, classes, croppedImage }) => {
  const [preview, setPreview] = useState(null);

  if (file) {
    const reader = new FileReader();

    reader.readAsDataURL(file);
    reader.onload = () => {
      setPreview(reader.result);
    };
  }
  return (
    <img
      src={
        croppedImage || preview || (imageValue && `${BASE_URL}${imageValue}`)
      }
      alt="preview"
      variant="square"
      className={classes}
    />
  );
};

export default ImagePreview;

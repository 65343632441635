import React, { useState } from "react";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import { Post } from "../api";
import { BASE_URL } from "../constants";
// import ImagePreview from "./ImagePreview";
import MediaPreview from "./MediaPreview";
// import VideoPreview from "./VideoPreview";
import * as loadImage from "blueimp-load-image";
import * as EXIF from "exif-js";
import { useRef } from "react";

const initialState = {
  title: "",
  description: "",
  images: [],
  video: [],
};

const CreatePost = () => {
  const { user, token } = useSelector((state) => state.user);

  const [postState, setPostState] = useState(initialState);
  const [loading, setLoading] = useState(false);

  const [imageBlobs, setImageBlobs] = useState([]);

  // const getOrientation = async (image) => {
  //   const exifData = await new Promise((resolve) => {
  //     EXIF.getData(image, function () {
  //       // console.log(EXIF.getAllTags(this));
  //       resolve(EXIF.getAllTags(this, "orientation"));
  //     });
  //   });
  //   return exifData;
  // };

  const setAllBlobs = async (files) => {
    const images = [];

    for (const image of files) {
      if (image && image.name) {
        const exifData = await new Promise((resolve) => {
          EXIF.getData(image, function () {
            // console.log(EXIF.getAllTags(this));
            resolve(EXIF.getAllTags(this, "orientation"));
          });
        });

        let img = new Image();
        img.src = URL.createObjectURL(image);
        img.onload = async () => {
          let width = img.width,
            height = img.height,
            canvas = document.createElement("canvas"),
            ctx = canvas.getContext("2d");
          // set proper canvas dimensions before transform & export
          // if (4 < exifData.Orientation && exifData.Orientation < 9) {
          //   canvas.width = height;
          //   canvas.height = width;
          // } else {
          //   canvas.width = width;
          //   canvas.height = height;
          // }
          canvas.width = width;
          canvas.height = height;
          switch (exifData.Orientation) {
            case 2:
              ctx.transform(-1, 0, 0, 1, width, 0);
              break;
            case 3:
              ctx.transform(-1, 0, 0, -1, width, height);
              break;
            case 4:
              ctx.transform(1, 0, 0, -1, 0, height);
              break;
            case 5:
              ctx.transform(0, 1, 1, 0, 0, 0);
              break;
            case 6:
              ctx.rotate("-90deg");
              break;
            case 7:
              ctx.transform(0, -1, -1, 0, height, width);
              break;
            case 8:
              ctx.transform(0, -1, 1, 0, 0, width);
              break;
            default:
              break;
          }
          console.log(ctx);
          ctx.drawImage(img, 0, 0);
          canvas.toBlob((blob) => {
            console.log(blob);
            blob.name = "newFile.jpeg";
            images.push(blob);
            console.log(imageBlobs);
            setImageBlobs((prevState) => [...prevState, blob]);
          }, "image/jpeg");
        };
      }
    }
  };
  console.log(imageBlobs);

  const createPostHandler = async () => {
    // Post(`api/v2/app/feeds`);
    setLoading(true);

    if (postState.images.length + postState.video.length > 4) {
      toast.warn("Can not select more than 4 media files");
      setLoading(false);
      setPostState(initialState);
      return;
    }

    const trimTitle = postState.title.trim();
    const trimDescription = postState.description;

    if (
      trimTitle.length === 0 ||
      (postState.description.length > 0 && trimDescription.trim().length === 0)
    ) {
      toast.warn(
        "Title or description cannot include leading and trailing spaces"
      );
      setLoading(false);
      setPostState(initialState);
      return;
    }

    const formData = new FormData();
    formData.append("title", postState.title);
    formData.append("description", postState.description);
    // const testImages = [...postState.images];

    // formData.append("title", postState.title);
    if (postState.video.length > 0) {
      formData.append("video", postState.video[0]);
    }
    console.log(imageBlobs);
    if (imageBlobs.length > 0) {
      imageBlobs.forEach((image) => {
        formData.append("image", image);
      });
      setImageBlobs([]);
    }

    // console.log(postState);
    const { data } = await Post(formData, `api/v2/app/feeds`, {
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: `Bearer ${token}`,
      },
    });
    if (data.status) {
      toast.success(data.message);
    }
    setLoading(false);
    setPostState(initialState);
  };

  const removePostHandler = (postIndex, type) => {
    if (type === "image") {
      const files = [...postState.images];
      const blobFiles = [...imageBlobs];
      const images = [];
      const blobs = [];
      for (const index in files) {
        if (Number(index) !== Number(postIndex)) {
          images.push(files[index]);
          blobs.push(blobFiles[index]);
        }
      }
      // delete images[postIndex];

      setPostState((prevState) => ({ ...prevState, images: [...images] }));
      setImageBlobs([...blobs]);
    } else {
      setPostState((prevState) => ({ ...prevState, video: [] }));
    }
  };

  return (
    <div className="create-post card">
      <div className="create-post-in d-flex">
        <div className="avatar">
          {user.image ? (
            <img src={`${BASE_URL}${user.image}`} alt="..." />
          ) : (
            <img src="/images/default.jpeg" alt="..." />
          )}
        </div>
        <div className="create-action w-100">
          <div className="form-group mb-0">
            <input
              type="text"
              name="title"
              id="title"
              className="form-control"
              placeholder="Enter title"
              value={postState.title}
              onChange={(event) =>
                setPostState((prevState) => ({
                  ...prevState,
                  title: event.target.value,
                }))
              }
            />
          </div>
          <hr />
          <div className="form-group mb-0">
            <textarea
              className="form-control"
              placeholder="What's happening?"
              value={postState.description}
              onChange={(event) =>
                setPostState((prevState) => ({
                  ...prevState,
                  description: event.target.value,
                }))
              }
            ></textarea>
          </div>
          <div class="upload-preview">
            <div class="row">
              <MediaPreview
                postState={postState}
                setPostState={setPostState}
                removePostHandler={removePostHandler}
              />
            </div>
          </div>
        </div>
      </div>
      <div className="create-post-footer">
        <div className="d-flex align-items-center">
          <div className="image-video-btn" style={{ cursor: "pointer" }}>
            <button style={{ cursor: "pointer" }}>
              <img
                src="/images/image-03.svg"
                alt="..."
                style={{ cursor: "pointer" }}
              />
              <input
                style={{ cursor: "pointer" }}
                type="file"
                name="image"
                multiple
                id="image"
                accept="image/*"
                onChange={async (event) => {
                  await setAllBlobs([...event.target.files]);
                  setPostState((prevState) => ({
                    ...prevState,
                    images: [...prevState.images, ...event.target.files],
                  }));
                }}
              />
            </button>
            <button>
              <img src="/images/video-recorder.svg" alt="..." />
              <input
                style={{ cursor: "pointer" }}
                type="file"
                name="video"
                id="video"
                accept="video/*"
                onChange={(event) =>
                  setPostState((prevState) => ({
                    ...prevState,
                    video: [...event.target.files],
                  }))
                }
              />
            </button>
          </div>
          <div className="ms-auto">
            <button
              className="btn btn-blue"
              type="button"
              onClick={createPostHandler}
              disabled={
                loading ||
                !(
                  postState.title.length > 0 &&
                  (postState.description.length > 0 ||
                    postState.images.length > 0 ||
                    postState.video.length > 0)
                )
              }
            >
              Share post
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CreatePost;

// types
import { createSlice } from "@reduxjs/toolkit";
// import axios from "axios";
// import { BASE_URL } from "config";
// import { toast } from "react-toastify";

// const USER_BASE_URL = `${BASE_URL}api/v2/user`;

// initial state
const initialState = {
  status: false,
};

const header = createSlice({
  name: "header",
  initialState,
  reducers: {
    setHeaderStatus: (state, action) => {
      state.status = !state.status;
    },
  },
  extraReducers: {},
});

export default header.reducer;

export const { setHeaderStatus } = header.actions;

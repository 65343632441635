import React, { useEffect, useState } from "react";
import { Get, Patch } from "../../api";
import SideBar from "../../Components/SideBar";
import { BASE_URL } from "../../constants";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import FeedList from "../../Components/FeedList";
import CreatePost from "../../Components/CreatePost";
import Loader from "../../Components/Loader";
import InfiniteScroll from "react-infinite-scroll-component";

const Home = () => {
  const [feeds, setFeeds] = useState([]);
  const [loading, setLoading] = useState(true);
  const [docCount, setDocCount] = useState(true);
  const [page, setPage] = useState(1);
  const [hasData, setHasData] = useState(true);

  // const [isLikedByUser, setIsLikedByUser] = useState(false);

  // const {user} = use
  // let isLikedByUser = [];
  const { token } = useSelector((state) => state.user);
  const { location } = useSelector((state) => state.location);

  useEffect(() => {
    (async () => {
      setLoading(true);
      const { data } = await Get(`api/v2/app/feeds?location=${location}`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      });
      if (data) {
        setFeeds(data.feeds);
        setDocCount(data.docCount);
      }
      setLoading(false);
    })();
  }, [token, setFeeds, location]);

  const feedPaginationHandler = async () => {
    setPage(page + 1);
    const { data } = await Get(
      `api/v2/app/feeds?page=${page + 1}&location=${location}`,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    );
    setHasData(page * 10 < data.docCount);
    setFeeds((prevState) => [...prevState, ...data.feeds]);
  };

  return (
    // <div className="site-container">
    //   <div className="container">

    <div className="container-relative">
      <SideBar />
      <div className="in-container">
        <CreatePost />
        {loading ? (
          <Loader />
        ) : (
          <InfiniteScroll
            dataLength={feeds.length}
            next={feedPaginationHandler}
            hasMore={hasData}
            style={{ overflow: "visible" }}
          >
            {feeds.length > 0 ? (
              feeds.map((feed, index) => (
                <FeedList
                  key={index}
                  comment
                  feed={feed}
                  feeds={feeds}
                  setFeeds={setFeeds}
                />
              ))
            ) : (
              <div class="post-wrap-outer">
                <div class="card no-post">
                  <div class="no-post-in text-center">
                    <figure>
                      <img src="/images/not-post.svg" alt=".." />
                    </figure>
                    <h4>No posts yet!</h4>
                  </div>
                </div>
              </div>
            )}
          </InfiniteScroll>
        )}
      </div>
    </div>
  );
};

export default Home;

import React from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { BASE_URL } from "../constants";

const UserProfile = ({ thisUser, postCount, followHandler }) => {
  console.log(thisUser);
  // console.log(postCount);
  const { user } = useSelector((state) => state.user);
  return (
    <div className="my-profile card">
      <div className="page-head mb-4">
        <div className="d-flex align-items-center">
          <Link to="/" className="btn btn-icon">
            <img src="/images/arrow-left.svg" alt="..." />
          </Link>
          {/* <a href="javascript:;" className="btn btn-icon ms-auto">
            <img src="/images/plus.svg" />
          </a> */}
        </div>
      </div>
      <div className="banner-profile">
        {thisUser.backgroundImage ? (
          <img src={`${BASE_URL}${thisUser.backgroundImage}`} alt="..." />
        ) : (
          <img src="/images/headerBg3.png" alt="..." />
        )}
      </div>
      <div className="my-profile-info">
        <div className="big-profile">
          <figure className="avatar">
            {thisUser.image ? (
              <img src={`${BASE_URL}${thisUser.image}`} alt="..." />
            ) : (
              <img src="/images/default.jpeg" alt="..." />
            )}
          </figure>
          {thisUser.isVerified && (
            <img
              src="/images/check-verified-02.svg"
              alt="..."
              className="verified-tag"
            />
          )}
        </div>
        {thisUser.id.toString() === user._id.toString() && (
          <Link to="/edit-profile" className="btn btn-icon">
            <img src="/images/edit-03.svg" alt="..." />
          </Link>
        )}

        <div className="my-profile-name text-center">
          <h4>{thisUser.fullName}</h4>
          <p>{thisUser.briefIntro}</p>
        </div>
        <div className="profile-statics">
          <a>
            <h4>{postCount}</h4>
            <span>Posts</span>
          </a>
          <Link to={`/connections/${thisUser.name}/followers`}>
            {/* <a href="javascript:;"> */}
            <h4>{thisUser.follower}</h4>
            <span>Followers</span>
            {/* </a> */}
          </Link>
          <Link to={`/connections/${thisUser.name}/following`}>
            {/* <a href="javascript:;"> */}
            <h4>{thisUser.following}</h4>
            <span>Following</span>
            {/* </a> */}
          </Link>
        </div>
        {user._id.toString() !== thisUser.id && (
          <div class="follow-btn text-center mb-3">
            {thisUser.followed ? (
              <button class="btn btn-gry" type="button" onClick={followHandler}>
                Following
              </button>
            ) : (
              <button
                class="btn btn-blue"
                type="button"
                onClick={followHandler}
              >
                Follow
              </button>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default UserProfile;

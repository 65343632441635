import React from "react";
import { Post } from "../../api";
import SideBar from "../../Components/SideBar";
import { Formik } from "formik";
import * as Yup from "yup";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import { Link } from "react-router-dom";

const ContactUs = () => {
  const { user, token } = useSelector((state) => state.user);

  return (
    <div className="container-relative">
      <SideBar />
      <div className="in-container">
        <div className="page-head mb-4">
          <div className="d-flex align-items-center">
            <Link to="/settings" className="btn btn-icon">
              <img src="/images/arrow-left.svg" alt="..." />
            </Link>
            <h4 className="ms-3">Contact us</h4>
          </div>
        </div>
        <div className="card verified-wrap">
          <div className="verified-wrap-in">
            <div className="verified-head mb-3">
              <figure className="mb-3">
                <img src="/images/contact.svg" />
              </figure>
              <figcaption>
                <h4>Get in touch</h4>
                <p>Always within your reach</p>
              </figcaption>
            </div>
            <Formik
              initialValues={{
                firstName: "",
                lastName: "",
                email: "",
                message: "",
              }}
              enableReinitialize
              validationSchema={Yup.object().shape({
                firstName: Yup.string().required("First name required").max(20),
                email: Yup.string()
                  .email("Please enter valid email")
                  .required("email address required"),
                message: Yup.string().required("Message required").max(200),
              })}
              onSubmit={async (
                values,
                { setErrors, setStatus, setSubmitting, resetForm }
              ) => {
                try {
                  const { data } = await Post(values, "api/v2/app/contact-us", {
                    headers: {
                      "Content-Type": "application/json",
                      Authorization: `Bearer ${token}`,
                    },
                  });

                  if (data.status) {
                    toast.success(data.message);
                  }
                  //   if (response && response.data.token) {
                  //     // console.log(response.data.user);
                  //     dispatch(
                  //       setTokenAndUser({
                  //         token: response.data.token,
                  //         user: response.data.user,
                  //       })
                  //     );
                  //   }
                  resetForm();
                  setStatus({ success: false });
                  setSubmitting(false);
                } catch (err) {
                  setStatus({ success: false });
                  setErrors({ submit: err.message });
                  setSubmitting(false);
                }
              }}
            >
              {({
                errors,
                handleBlur,
                handleChange,
                handleSubmit,
                isSubmitting,
                touched,
                values,
                setFieldValue,
              }) => (
                <form noValidate onSubmit={handleSubmit}>
                  <div className="form-group">
                    <label>First Name</label>
                    <input
                      type="text"
                      name="firstName"
                      id="firstName"
                      className="form-control"
                      value={values.firstName}
                      onChange={handleChange}
                      placeholder="Enter first name"
                      onBlur={handleBlur}
                    />
                    {errors.firstName && touched.firstName && (
                      <div className="invalid-feedback-custom">
                        {errors.firstName}
                      </div>
                    )}
                  </div>
                  <div className="form-group">
                    <label>Last Name</label>
                    <input
                      type="text"
                      name="lastName"
                      id="lastName"
                      className="form-control"
                      onChange={handleChange}
                      value={values.lastName}
                      placeholder="Enter last name"
                      onBlur={handleBlur}
                    />
                  </div>
                  <div className="form-group">
                    <label>Email address</label>
                    <input
                      type="text"
                      name="email"
                      id="email"
                      className="form-control"
                      onChange={handleChange}
                      value={values.email}
                      placeholder="Enter email"
                      onBlur={handleBlur}
                    />
                    {errors.email && touched.email && (
                      <div className="invalid-feedback-custom">
                        {errors.email}
                      </div>
                    )}
                  </div>
                  <div className="form-group">
                    <label>Message</label>
                    <textarea
                      name="message"
                      id="message"
                      className="form-control"
                      onChange={handleChange}
                      value={values.message}
                      placeholder="Enter your message for us here..."
                      style={{ height: "100px" }}
                      onBlur={handleBlur}
                    ></textarea>
                    {errors.message && touched.message && (
                      <div className="invalid-feedback-custom">
                        {errors.message}
                      </div>
                    )}
                  </div>

                  <button className="btn btn-blue w-100" type="submit">
                    Submit
                  </button>
                </form>
              )}
            </Formik>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ContactUs;

// import { lazy } from "react";

// project import
// import Loadable from "components/Loadable";
// import MinimalLayout from "layout/MinimalLayout";
import { Navigate } from "react-router-dom";
// import ChangePassword from "pages/authentication/auth-forms/ChangePassword";
import Layout from "../Components/Layout";
import ChangePassword from "../Pages/Auth/ChangePassword";
import ForgotPassword from "../Pages/Auth/ForgotPassword";
import Login from "../Pages/Auth/Login";
import Register from "../Pages/Auth/Register";
import Verification from "../Pages/Auth/Verification";

// import { lazy } from "react";

// const ChangePassword = lazy(() => import("../Pages/Auth/ChangePassword"));
// const ForgotPassword = lazy(() => import("../Pages/Auth/ForgotPassword"));
// const Login = lazy(() => import("../Pages/Auth/Login"));
// const Register = lazy(() => import("../Pages/Auth/Register"));
// const Verification = lazy(() => import("../Pages/Auth/Verification"));

// render - login
// const AuthLogin = Loadable(lazy(() => import("pages/authentication/Login")));

// const AuthForgotPassword = Loadable(
//   lazy(() => import("pages/authentication/ForgotPassword"))
// );
// const AuthResetPassword = Loadable(
//   lazy(() => import("pages/authentication/ResetPassword"))
// );

// ==============================|| AUTH ROUTING ||============================== //

const AuthRoutes = (isLoggedIn) => {
  return {
    path: "/",
    element: isLoggedIn ? (
      <Navigate to="/" replace={true} />
    ) : (
      <Layout isLoggedIn={isLoggedIn} />
    ),
    children: [
      {
        path: "/signup",
        element: <Register />,
        // errorElement: <ErrorPage />,
        // loader: homeLoader,
      },
      {
        path: "/login",
        element: <Login />,
        // errorElement: <ErrorPage />,
        // loader: homeLoader,
      },
      {
        path: "/forgot-password",
        element: <ForgotPassword />,
        // errorElement: <ErrorPage />,
        // loader: homeLoader,
      },
      {
        path: "/verification",
        element: <Verification />,
        // errorElement: <ErrorPage />,
        // loader: homeLoader,
      },
      {
        path: "/change-password",
        element: <ChangePassword />,
        // errorElement: <ErrorPage />,
        // loader: homeLoader,
      },
    ],
  };
};

export default AuthRoutes;

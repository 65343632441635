import React from "react";
import { Link } from "react-router-dom";

const Footer = () => {
  return (
    <footer>
      <div className="container">
        <div className="row">
          <div className="col-md-6 text-start">
            © 2023 Ootky. All rights reserved.
          </div>
          <div className="col-md-6 text-end">
            <Link to="/cms?page=Terms-Conditions">Terms & Conditions</Link>
            <Link to="cms?page=Privacy Policy">Privacy Policy</Link>
            <Link to="/contact-us">Contact Us</Link>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;

import React from "react";
import { Outlet } from "react-router";
import Footer from "./Footer";
import Header from "./Header";
import MainHeader from "./MainHeader";

const Layout = ({ isLoggedIn }) => {
  return (
    <div className="wraper-main">
      {isLoggedIn ? <MainHeader /> : <Header />}
      <div className="site-container">
        <div className="container">
          <Outlet />
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Layout;

import React, { useState } from "react";

const VideoPreview = ({ file, videoValue }) => {
  const [preview, setPreview] = useState(null);

  if (file) {
    const reader = new FileReader();

    reader.readAsDataURL(file);
    reader.onload = () => {
      setPreview(reader.result);
    };
  }

  return (
    <video controls src={preview} style={{ width: "100%" }}>
      {/* <source src={preview} /> */}
      {/* <source src="movie.ogg" type="video/ogg" /> */}
      Your browser does not support the video tag.
    </video>
  );
};

export default VideoPreview;

import moment from "moment";
import React, { useRef, useState } from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { BASE_URL } from "../constants";

const Comments = ({
  image,
  comments,
  addCommentHandler,
  feedId,
  replyHandler,
  commentDeleteHandler,
  mediaId,
  deleteReplyHandler,
  commentEditHandler,
  editingComment,
  editReplyHandler,
  showMoreComments,
  showLessComments,
  commentCount,
  showMoreDisable,
  showLessDisable,
  textRef,
  replyRef,
}) => {
  const [content, setContent] = useState("");
  const [reply, setReply] = useState("");

  // const disabledCheck = (content) => {
  //   console.log(content);
  //   if (content.trim().length === 0) {
  //     setContent("");
  //     setReply("");
  //     return true;
  //   } else {
  //     return false;
  //   }
  // };

  // useEffect;

  const onCommentChangeHandler = function (e) {
    const target = e.target;
    textRef.current.style.height = "42px";
    textRef.current.style.height = `${target.scrollHeight}px`;
    setContent(target.value);
  };

  const onReplyChangeHandler = function (e) {
    const target = e.target;
    replyRef.current.style.height = "42px";
    replyRef.current.style.height = `${target.scrollHeight}px`;
    setReply(target.value);
  };

  const { user } = useSelector((state) => state.user);

  return (
    <div className="post-comments">
      <div className="add-comment d-flex">
        <figure className="avatar">
          <Link to="/">
            {image ? (
              <img src={`${BASE_URL}${image}`} alt="..." />
            ) : (
              <img src="/images/default.jpeg" alt="..." />
            )}
          </Link>
        </figure>
        <figcaption>
          <div className="form-group send-input-btn">
            <textarea
              type="text"
              name="content"
              id="content"
              value={content}
              onChange={onCommentChangeHandler}
              placeholder="Add a comment…"
              className="form-control"
              ref={textRef}
              style={{
                width: "80%",
              }}
            />
            <button
              type="button"
              onClick={() =>
                addCommentHandler(
                  feedId,
                  content,
                  setContent,
                  setReply,
                  null,
                  mediaId
                )
              }
            >
              {editingComment ? "Edit Comment" : "Add Comment"}
            </button>
          </div>
        </figcaption>
      </div>
      {comments.length > 0 && (
        <ul style={{ maxHeight: "50vh", overflowY: "scroll" }}>
          {comments.map((comment, index) => (
            <li key={index}>
              <div className="comment-box">
                <figure>
                  <Link to={`/${comment.user.userName}`} className="avatar">
                    {comment && comment.user && comment.user.image ? (
                      <img src={`${BASE_URL}${comment.user.image}`} alt="..." />
                    ) : (
                      <img src="/images/default.jpeg" alt="..." />
                    )}
                  </Link>
                </figure>
                <figcaption>
                  <div>
                    <h4>
                      <Link to={`/${comment.user.userName}`}>
                        {comment.user.fullName}
                      </Link>
                    </h4>
                    <p style={{ wordBreak: "break-all" }}>{comment.content}</p>
                    <button
                      type="button"
                      onClick={() => replyHandler(feedId, comment._id, mediaId)}
                      className="reply-text"
                    >
                      Reply
                    </button>
                    {comment.showReply && comment.replies && (
                      <div className="reply-box">
                        <div className="form-group mb-0 mt-2 send-input-btn">
                          <textarea
                            type="text"
                            name="reply"
                            id="reply"
                            value={reply}
                            placeholder="Add a reply…"
                            className="form-control"
                            onChange={onReplyChangeHandler}
                            ref={replyRef}
                            style={{
                              width: "85%",
                            }}
                          />
                          <button
                            type="button"
                            onClick={() =>
                              addCommentHandler(
                                feedId,
                                reply,
                                setContent,
                                setReply,
                                comment._id,
                                mediaId
                              )
                            }
                          >
                            <img src="/images/send-03.svg" alt="..." />
                          </button>
                        </div>
                      </div>
                    )}
                  </div>
                  <div className="time-and-more">
                    <span>{moment(comment.createdAt).fromNow()}</span>

                    {user._id.toString() === comment.user._id.toString() && (
                      <div className="dropdown">
                        <button
                          className="btn dropdown-toggle"
                          type="button"
                          id="dropdownMenuButton1"
                          data-bs-toggle="dropdown"
                          aria-expanded="false"
                        >
                          <img src="/images/dots-vertical.svg" />
                        </button>
                        <ul
                          className="dropdown-menu"
                          aria-labelledby="dropdownMenuButton1"
                        >
                          <li>
                            <button
                              type="button"
                              onClick={() =>
                                commentEditHandler(comment, setContent)
                              }
                              className="dropdown-item"
                            >
                              <img src="/images/edit-03.svg" alt="..." /> Edit
                              comment
                            </button>
                          </li>
                          <li>
                            <button
                              type="button"
                              onClick={() =>
                                commentDeleteHandler(feedId, comment._id)
                              }
                              className="dropdown-item"
                            >
                              <img src="/images/trash-03.svg" alt="..." />
                              Delete comment
                            </button>
                          </li>
                        </ul>
                      </div>
                    )}
                  </div>
                </figcaption>
              </div>
              {comment.replies &&
                comment.replies.length > 0 &&
                comment.replies[0].content && (
                  <>
                    {comment.replies.map((reply, index) => (
                      <div className="comment-box comment-box-in" key={index}>
                        <figure>
                          <Link
                            to={`/${reply.user.userName}`}
                            className="avatar"
                          >
                            {reply && reply.user && reply.user.image ? (
                              <img
                                src={`${BASE_URL}${reply.user.image}`}
                                alt="..."
                              />
                            ) : (
                              <img src="/images/default.jpeg" alt="..." />
                            )}
                          </Link>
                        </figure>
                        <figcaption>
                          <div>
                            <h4>
                              <Link to={`/${reply.user.userName}`}>
                                {reply.user.fullName}
                              </Link>
                            </h4>
                            <p style={{ wordBreak: "break-all" }}>
                              {reply.content}
                            </p>
                            {/* <a href="javascript:;" className="reply-text">
                              Reply
                            </a> */}
                          </div>
                          <div className="time-and-more">
                            <span>{moment(reply.createdAt).fromNow()}</span>
                            {user._id.toString() ===
                              reply.user._id.toString() && (
                              <div className="dropdown">
                                <button
                                  className="btn dropdown-toggle"
                                  type="button"
                                  id="dropdownMenuButton1"
                                  data-bs-toggle="dropdown"
                                  aria-expanded="false"
                                >
                                  <img src="/images/dots-vertical.svg" />
                                </button>
                                <ul
                                  className="dropdown-menu"
                                  aria-labelledby="dropdownMenuButton1"
                                >
                                  <li>
                                    <button
                                      type="button"
                                      className="dropdown-item"
                                      onClick={() => {
                                        replyHandler(
                                          feedId,
                                          comment._id,
                                          null,
                                          "edit"
                                        );
                                        editReplyHandler(
                                          comment._id,
                                          reply,
                                          setReply
                                        );
                                      }}
                                    >
                                      <img src="/images/edit-03.svg" /> Edit
                                      Reply
                                    </button>
                                  </li>
                                  <li>
                                    <button
                                      type="button"
                                      className="dropdown-item"
                                      onClick={() =>
                                        deleteReplyHandler(
                                          comment._id,
                                          reply._id
                                        )
                                      }
                                    >
                                      <img src="/images/trash-03.svg" />
                                      Delete Reply
                                    </button>
                                  </li>
                                </ul>
                              </div>
                            )}
                          </div>
                        </figcaption>
                      </div>
                    ))}
                  </>
                )}
            </li>
          ))}

          {commentCount > 5 && (
            <div className="comment-box-pagination">
              <button
                className="comment"
                style={
                  showMoreDisable
                    ? {
                        color: "grey",
                        backgroundColor: "transparent",
                        border: "none",
                      }
                    : {
                        color: "#1E74FC",
                        backgroundColor: "transparent",
                        border: "none",
                      }
                }
                onClick={() => showMoreComments(feedId)}
                disabled={showMoreDisable}
              >
                Show More
              </button>
              <button
                style={
                  showLessDisable
                    ? {
                        color: "grey",
                        backgroundColor: "transparent",
                        border: "none",
                      }
                    : {
                        color: "#1E74FC",
                        backgroundColor: "transparent",
                        border: "none",
                      }
                }
                onClick={() => showLessComments(feedId)}
                disabled={showLessDisable}
              >
                Show Less
              </button>
            </div>
          )}
        </ul>
      )}
    </div>
  );
};

export default Comments;

import { useSelector } from "react-redux";
import { createBrowserRouter, RouterProvider } from "react-router-dom";

// project import
import AuthRoutes from "./AuthRoutes";
import MainRoutes from "./MainRoutes";
import SharedRoutes from "./SharedRoutes";

// ==============================|| ROUTING RENDER ||============================== //

export default function Routes() {
  const { token } = useSelector((state) => state.user);
  let isLoggedIn = false;
  if (token) {
    isLoggedIn = true;
  }

  //   , AuthRoutes(isLoggedIn)
  // return useRoutes([MainRoutes(isLoggedIn)]);

  const router = createBrowserRouter([
    MainRoutes(isLoggedIn),
    AuthRoutes(isLoggedIn),
    SharedRoutes(),
  ]);

  return <RouterProvider router={router} />;
}

import React, { useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import { BASE_URL } from "../constants";
import VisibilitySensor from "react-visibility-sensor";
// import Carousel from "react-bootstrap/Carousel";

const ReactCarousel = ({ media }) => {
  const videoRef = useRef(null);
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    if (videoRef && videoRef.current) {
      console.log(videoRef);
      if (isVisible) {
        // videoRef.current.play();
      } else {
        if (videoRef.current.play) {
          videoRef.current.pause();
        }
      }
    }
  }, [isVisible]);

  return (
    <div className="item">
      {/* <Link data-fancybox="gallery" to={`${media.path}`}> */}
      <div className="post-images-in">
        {media.type.toLowerCase() === "image" ? (
          <img src={`${BASE_URL}${media.path}`} alt="..." />
        ) : (
          <VisibilitySensor onChange={(isVisible) => setIsVisible(isVisible)}>
            <video controls ref={videoRef}>
              <source src={`${BASE_URL}${media.path}`} type="video/mp4" />
              Your browser does not support the video tag.
            </video>
          </VisibilitySensor>
        )}
      </div>
      {/* </Link> */}
    </div>
  );
  // return (
  //   <Carousel.Item>
  //     {media.type.toLowerCase() === "image" ? (
  //       <img src={`${BASE_URL}${media.path}`} alt="..." />
  //     ) : (
  //       <video controls>
  //         <source src={`${BASE_URL}${media.path}`} type="video/mp4" />
  //         Your browser does not support the video tag.
  //       </video>
  //     )}
  //   </Carousel.Item>
  // );
};

export default ReactCarousel;

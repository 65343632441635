// third-party
import { combineReducers } from "redux";

// project import
import user from "./user";
import header from "./header";
import socket from "./socket";
import location from "./currentLocation";
// ==============================|| COMBINE REDUCERS ||============================== //

const reducers = combineReducers({
  user,
  socket,
  header,
  location,
});

export default reducers;

import React, { useState } from "react";
import OtpInput from "react-otp-input";
import { Post } from "../../api";
import { Formik } from "formik";
import * as Yup from "yup";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

const useQuery = () => {
  return new URLSearchParams(useLocation().search);
};

const Verification = () => {
  //   const [mobile, setMobile] = useState("");
  const query = useQuery();
  const mobile = query.get("mobile");
  const type = query.get("type") || "verify";
  //   setMobile(_mobile);

  const navigate = useNavigate();

  const [otp, setOtp] = useState("");

  const handleSubmit = async (event) => {
    event.preventDefault();
    const response = await Post(
      { mobile, type, otp },
      "api/v2/app/user/verifyOtp",
      {
        headers: {
          "Content-Type": "application/json",
          // Authorization: `Bearer ${token}`,
        },
      }
    );
    if (response && response.data && response.data.status) {
      if (type === "reset") {
        toast.success("Otp is correct! Please create a new password");
        navigate(`../change-password?mobile=${mobile}`, { replace: true });
      } else {
        toast.success(response.data.message);
        navigate(`../`, { replace: true });
      }
    }
  };

  const handleChange = (otp) => {
    setOtp(otp);
  };

  return (
    <div className="auth-form card-shadow p-5">
      <div className="login-form-head">
        <img src="/images/otp.svg" alt="..." />
        <h4>Verification</h4>
        <p>
          Enter the verification code we just sent you on your Mobile number
        </p>
        <h6 style={{ color: "blue" }}>{mobile}</h6>
      </div>
      <div className="form-box">
        <form
          className="needs-validation"
          noValidate
          onSubmit={(event) => handleSubmit(event)}
        >
          {/* <div className="row">
                <div className="col-md-3">
                  <div className="form-group otp-input">
                    <input
                      type="text"
                      className="form-control"
                      id="validationCustomUsername"
                      aria-describedby="inputGroupPrepend"
                      required=""
                      placeholder=""
                    />
                  </div>
                </div>
                <div className="col-md-3">
                  <div className="form-group otp-input">
                    <input
                      type="text"
                      className="form-control"
                      id="validationCustomUsername"
                      aria-describedby="inputGroupPrepend"
                      required=""
                      placeholder=""
                    />
                  </div>
                </div>
                <div className="col-md-3">
                  <div className="form-group otp-input">
                    <input
                      type="text"
                      className="form-control"
                      id="validationCustomUsername"
                      aria-describedby="inputGroupPrepend"
                      required=""
                      placeholder=""
                    />
                  </div>
                </div>
                <div className="col-md-3">
                  <div className="form-group otp-input">
                    <input
                      type="text"
                      className="form-control"
                      id="validationCustomUsername"
                      aria-describedby="inputGroupPrepend"
                      required=""
                      placeholder=""
                    />
                  </div>
                </div>
              </div> */}
          <div className="row">
            <div className="col-md-12">
              <div className="form-group otp-input">
                <OtpInput
                  value={otp}
                  onChange={handleChange}
                  numInputs={4}
                  // name:"otp"
                  // id:"otp"
                  separator={<span> </span>}
                  isInputNum={true}
                  //   className="form-control"
                  containerStyle={{ justifyContent: "space-around" }}
                  inputStyle={{
                    height: "62px",
                    border: "none",
                    background: "#F4F5F7",
                    borderRadius: "8px !important",
                    textAlign: "center",
                    fontSize: "26px",
                    fontWeight: "700",
                    width: "70%",
                    margin: "0 auto",
                  }}
                  focusStyle={{}}
                />
              </div>
            </div>
          </div>

          <button className="btn btn-blue w-100" type="submit">
            Verify
          </button>
          <div className="forgot-link text-center mt-4">
            Didn't receive the code? <a href="javascript:;">Resend</a>
          </div>
        </form>
      </div>
    </div>
  );
};

export default Verification;

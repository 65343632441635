import React, { useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import { BASE_URL } from "../constants";
import moment from "moment";
import ReactCarousel from "./ReactCarousel";
import Carousel from "react-material-ui-carousel";
import { useSelector } from "react-redux";
import { Delete, Get, Patch } from "../api";
import Comments from "./Comments";
import EditPostModal from "./EditPostModal";
import { toast } from "react-toastify";
import AlertDialogSlide from "./DeleteDilalog";
import VisibilitySensor from "react-visibility-sensor";
import Linkify from "react-linkify";
import ExifOrientationImg from "react-exif-orientation-img";

const FeedList = ({ feed, feeds, setFeeds }) => {
  const { user, token } = useSelector((state) => state.user);
  const { socket } = useSelector((state) => state.socket);
  const [open, setOpen] = useState(false);
  const [feedId, setFeedId] = useState(null);
  const [editingComment, setEditingComment] = useState(false);
  const [editCurrentComment, setEditCurrentComment] = useState({});
  const [deleteOpen, setDeleteOpen] = useState(false);
  const [limit, setLimit] = useState(5);

  const [editingReply, setEditingReply] = useState(false);
  const [editCurrentReply, setEditCurrentReply] = useState({});

  const [showMoreDisable, setShowMoreDisable] = useState(false);
  const [showLessDisable, setShowLessDisable] = useState(true);
  const textRef = useRef();
  const replyRef = useRef();
  // const [comments, setComments] = useState([]);
  const videoRef = useRef(null);
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    if (videoRef && videoRef.current) {
      console.log(videoRef);
      if (isVisible) {
        // videoRef.current.play();
      } else {
        if (videoRef.current.play) {
          videoRef.current.pause();
        }
      }
    }
  }, [isVisible]);

  const likeHandler = async (feed) => {
    const { data } = await Patch({}, `api/v2/app/feeds/like/${feed._id}`, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    });

    if (data) {
      const newData = [];
      // setIsLikedByUser(feed.likes.includes(user._id.toString()));
      // console.log(isLikedByUser);
      if (feed.likes.includes(user._id.toString())) {
        for (const _feed of feeds) {
          if (feed._id.toString() === _feed._id.toString()) {
            const obj = { ..._feed };
            obj.likeCount = _feed.likeCount - 1;
            obj.likes = _feed.likes.filter(
              (userId) => user._id.toString() !== userId.toString()
            );
            newData.push(obj);
          } else {
            newData.push(_feed);
          }
        }
      } else {
        for (const _feed of feeds) {
          if (feed._id.toString() === _feed._id.toString()) {
            const obj = { ..._feed };
            console.log(obj);
            obj.likeCount = obj.likeCount + 1;
            obj.likes.push(user._id);

            newData.push(obj);
          } else {
            newData.push(_feed);
          }
        }
      }
      setFeeds(newData);
    }
  };

  const shareHandler = async (link) => {
    if ("clipboard" in navigator) {
      return await navigator.clipboard.writeText(link);
    } else {
      return document.execCommand("copy", true, link);
    }
  };

  const editPostHandler = async (feedId) => {
    handleClickOpen();
  };

  const addCommentHandler = async (
    feedId,
    content,
    setContent,
    setReply,
    commentId
  ) => {
    setContent("");
    setReply("");
    if (textRef && textRef.current) {
      textRef.current.style.height = "42px";
    }
    if (replyRef && replyRef.current) {
      replyRef.current.style.height = "42px";
    }

    if (content.trim().length > 0) {
      if (editingComment) {
        const values = {
          content,
          commentId: editCurrentComment._id,
        };
        socket.emit("updateComment", values);
        setEditingComment(false);
        setEditCurrentComment({});
      } else if (editingReply) {
        const values = {
          content,
          replyId: editCurrentReply._id,
          commentId,
        };
        socket.emit("updateReply", values);
        setEditingReply(false);
        setEditCurrentReply({});
      } else {
        const values = {
          feedId,
          content,
          userId: user._id,
        };
        if (commentId) values.commentId = commentId;
        socket.emit("createComment", values);
      }
    } else {
      return;
    }
  };

  useEffect(() => {
    // if(){}
    socket.on("getCommentResponse", (data) => {
      // console.log(data);
      // setComments((prevState) => [...prevState, data.newComment]);
      const newData = [];
      for (const _feed of feeds) {
        if (data.newComment.feedId.toString() === _feed._id.toString()) {
          let isReply = false;
          for (const comment of _feed.comments) {
            if (data.newComment._id.toString() === comment._id.toString()) {
              isReply = true;

              comment.replies = [...data.newComment.replies].reverse();
            }
          }
          if (!isReply) {
            _feed.comments = [..._feed.comments, data.newComment];
            _feed.commentCount += 1;
          }

          const obj = { ..._feed };
          newData.push(obj);
        } else {
          newData.push(_feed);
        }
      }
      setFeeds(newData);
    });
  }, [socket, feed]);

  useEffect(() => {
    // if(){}
    socket.on("updateCommentResponse", (data) => {
      console.log(data);
      const newData = [];
      for (const _feed of feeds) {
        if (data.updatedComment.feedId.toString() === _feed._id.toString()) {
          // for (const comment of _feed.comments) {
          //   if (data.newComment._id.toString() === comment._id.toString()) {
          //     comment.replies = [...data.newComment.replies].reverse();
          //   }
          // }
          const obj = { ..._feed };

          const updatedComments = [];
          for (const comment of obj.comments) {
            if (comment._id.toString() === data.updatedComment._id.toString()) {
              updatedComments.push(data.updatedComment);
            } else {
              updatedComments.push(comment);
            }
          }

          obj.comments = [...updatedComments];

          newData.push(obj);
        } else {
          newData.push(_feed);
        }
      }
      setFeeds(newData);
    });
  }, [socket, feed]);

  useEffect(() => {
    // if(){}
    socket.on("updateReplyResponse", (data) => {
      console.log(data);
      const newData = [];
      for (const _feed of feeds) {
        if (data.updatedComment.feedId.toString() === _feed._id.toString()) {
          // for (const comment of _feed.comments) {
          //   if (data.newComment._id.toString() === comment._id.toString()) {
          //     comment.replies = [...data.newComment.replies].reverse();
          //   }
          // }
          const obj = { ..._feed };

          const updatedComments = [];
          for (const comment of obj.comments) {
            if (comment._id.toString() === data.updatedComment._id.toString()) {
              updatedComments.push(data.updatedComment);
            } else {
              updatedComments.push(comment);
            }
          }

          obj.comments = [...updatedComments];

          newData.push(obj);
        } else {
          newData.push(_feed);
        }
      }
      setFeeds(newData);
    });
  }, [socket, feed]);

  useEffect(() => {
    if (limit <= 5) {
      setShowLessDisable(true);
    } else {
      setShowLessDisable(false);
    }
  }, [limit]);

  const showMoreComments = async (feedId) => {
    const newLimit = limit + 5;
    setLimit(newLimit);
    const { data } = await Get(
      `api/v2/app/comment/${feedId}?limit=${newLimit}`,
      {
        headers: {
          "content-type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    );
    setShowMoreDisable(newLimit > data.totalComment);

    const newData = [];
    for (const _feed of feeds) {
      if (feedId.toString() === _feed._id.toString()) {
        _feed.comments = [...data.comments];
        const obj = { ..._feed };
        newData.push(obj);
      } else {
        newData.push(_feed);
      }
    }
    setFeeds(newData);
  };

  const showLessComments = async (feedId) => {
    const newLimit = 5;
    setLimit(newLimit);
    setShowMoreDisable(false);
    const { data } = await Get(`api/v2/app/comment/${feedId}?limit=${5}`, {
      headers: {
        "content-type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    });

    const newData = [];
    for (const _feed of feeds) {
      if (feedId.toString() === _feed._id.toString()) {
        _feed.comments = [...data.comments];
        const obj = { ..._feed };
        newData.push(obj);
      } else {
        newData.push(_feed);
      }
    }
    setFeeds(newData);
  };

  const commentHandler = async (id) => {
    const { data } = await Get(`api/v2/app/comment/${id}?limit=${limit}`, {
      headers: {
        "content-type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    });

    const newData = [];
    for (const _feed of feeds) {
      if (id.toString() === _feed._id.toString()) {
        _feed.comments = [...data.comments];
        _feed.commentCount = data.totalComment;
        const obj = { ..._feed };
        obj.commentShow = !obj.commentShow;
        newData.push(obj);
      } else {
        newData.push(_feed);
      }
    }
    setFeeds(newData);
  };

  const replyHandler = (feedId, commentId, mediaId, type) => {
    // console.log(feedId, commentId);
    const newData = [];
    for (const _feed of feeds) {
      if (feedId.toString() === _feed._id.toString()) {
        const obj = { ..._feed };
        // obj.commentShow = !obj.commentShow;
        // newData.push(obj);
        for (const _comment of obj.comments) {
          // if(comment.replies && comment)
          if (commentId.toString() === _comment._id.toString()) {
            // const obj = { ..._feed };
            if (type === "edit") {
              _comment.showReply = true;
            } else {
              _comment.showReply = !_comment.showReply;
            }

            // console.log(_comment);
          } else {
            _comment.showReply = false;
          }
        }
        // console.log(obj);
        newData.push(obj);
      } else {
        newData.push(_feed);
      }
    }
    setFeeds(newData);
  };

  const onConfirmation = async () => {
    const { status } = await Delete(`api/v2/app/feeds/${feedId}`, {
      headers: {
        "content-type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    });
    if (status) {
      const newData = [...feeds];
      const formattedData = newData.filter(
        (post) => post._id.toString() !== feedId.toString()
      );
      setFeeds([...formattedData]);
    }
    setDeleteOpen(false);
  };
  const deletePostHandler = async (id) => {
    setFeedId(id);
    setDeleteOpen(true);
  };

  const commentDeleteHandler = async (feedId, commentId) => {
    socket.emit("deleteComment", { commentId });
    // const { status } = await Delete(`api/v2/app/comment/${commentId}`, {
    //   headers: {
    //     "content-type": "application/json",
    //     Authorization: `Bearer ${token}`,
    //   },
    // });
    // if (status) {
    //   const newData = [...feeds];
    //   const formattedData = newData.filter(
    //     (post) => post._id.toString() !== id.toString()
    //   );
    //   setFeeds([...formattedData]);
    // }
  };

  const commentEditHandler = async (comment, setContent) => {
    setEditingComment(true);
    setEditCurrentComment(comment);
    setContent(comment.content);
    // socket.emit("updateComment", { commentId, content });
  };

  const editReplyHandler = async (commentId, reply, setReply) => {
    setEditingReply(true);
    setEditCurrentReply(reply);
    setReply(reply.content);
  };

  useEffect(() => {
    if (socket) {
      socket.on("updateCommentResponse", (data) => {
        console.log(data);
      });
    }
  }, [socket]);

  useEffect(() => {
    // if(){}
    socket.on("deleteCommentResponse", (data) => {
      // setComments((prevState) => [...prevState, data.newComment]);
      const newData = [];
      for (const _feed of feeds) {
        if (data.deletedComment.feedId.toString() === _feed._id.toString()) {
          // let isReply = false;
          // for (const comment of _feed.comments) {
          //   if (data.newComment._id.toString() === comment._id.toString()) {
          //     comment.replies = [...data.newComment.replies].reverse();
          //   }
          // }

          const obj = { ..._feed };

          obj.comments = obj.comments.filter(
            (comment) => comment._id.toString() !== data.deletedComment._id
          );
          obj.commentCount -= 1;
          newData.push(obj);
        } else {
          newData.push(_feed);
        }
      }
      setFeeds(newData);
    });
  }, [socket, feed]);

  const deleteReplyHandler = async (commentId, replyId) => {
    socket.emit("deleteReply", { commentId, replyId });
    // const { status } = await Delete(`api/v2/app/comment/${commentId}`, {
    //   headers: {
    //     "content-type": "application/json",
    //     Authorization: `Bearer ${token}`,
    //   },
    // });
    // if (status) {
    //   const newData = [...feeds];
    //   const formattedData = newData.filter(
    //     (post) => post._id.toString() !== id.toString()
    //   );
    //   setFeeds([...formattedData]);
    // }
  };
  useEffect(() => {
    // if(){}
    socket.on("deleteReplyResponse", (data) => {
      const newData = [];
      for (const _feed of feeds) {
        if (data.findComment.feedId.toString() === _feed._id.toString()) {
          // let isReply = false;
          // for (const comment of _feed.comments) {
          //   if (data.newComment._id.toString() === comment._id.toString()) {
          //     comment.replies = [...data.newComment.replies].reverse();
          //   }
          // }
          const obj = { ..._feed };
          // obj.comments = obj.comments.filter(
          //   (comment) => comment._id.toString() !== data.deletedComment._id
          // );

          for (const _comment of obj.comments) {
            if (_comment._id.toString() === data.findComment._id.toString()) {
              // const replies = [];
              const tempReplies = [];
              for (const reply of _comment.replies) {
                for (const _reply of data.findComment.replies) {
                  if (reply._id.toString() === _reply._id.toString()) {
                    tempReplies.push(reply);
                  }
                }
              }
              _comment.replies = [...tempReplies];
            }
          }

          newData.push(obj);
        } else {
          newData.push(_feed);
        }
      }
      setFeeds([...newData]);
    });
  }, [socket, feed]);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const editPostClickHandler = async (feedId, currentState) => {
    if (currentState.title.trim().length > 0) {
      if (
        currentState.description.length > 0 &&
        currentState.description.trim().length === 0
      ) {
        toast.warn("Please enter valid description");
        return;
      }
      const { data } = await Patch(currentState, `api/v2/app/feeds/${feedId}`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      });

      const newData = [];
      for (const _feed of feeds) {
        if (feedId.toString() === _feed._id.toString()) {
          const obj = { ..._feed };
          obj.title = currentState.title;
          obj.description = currentState.description;
          newData.push(obj);
        } else {
          newData.push(_feed);
        }
      }
      setFeeds(newData);

      toast.success(data.message);
      setOpen(false);
    } else {
      toast.warn("Please enter valid title");
    }
  };

  return (
    <>
      {feed && (
        <div className="post-wrap card">
          <div className="post-hd">
            <div className="post-profile">
              <figure className="avatar">
                <Link to={`/${feed.userName}`}>
                  {feed.userImage ? (
                    <img src={`${BASE_URL}${feed.userImage}`} alt="..." />
                  ) : (
                    <img src={"/images/default.jpeg"} alt="..." />
                  )}
                </Link>
              </figure>
              <figcaption>
                <div>
                  <h4>
                    <Link to={`/${feed.userName}`}>{feed.fullName}</Link>
                  </h4>
                  <span>{moment(feed.createdAt).fromNow()}</span>
                </div>
              </figcaption>
              {user._id.toString() === feed.userId.toString() && (
                <div className="my-post-option">
                  <div className="dropdown">
                    <button
                      className="btn dropdown-toggle"
                      type="button"
                      id="dropdownMenuButton1"
                      data-bs-toggle="dropdown"
                      aria-expanded="false"
                    >
                      <img src="/images/dots-vertical.svg" />
                    </button>
                    <ul
                      className="dropdown-menu"
                      aria-labelledby="dropdownMenuButton1"
                    >
                      <li>
                        <button
                          className="dropdown-item"
                          onClick={() => editPostHandler(feed)}
                        >
                          <img src="/images/edit-03.svg" />
                          Edit Post
                        </button>
                      </li>
                      <li>
                        <button
                          className="dropdown-item"
                          onClick={() => deletePostHandler(feed._id)}
                          type="button"
                        >
                          <img src="/images/trash-03.svg" alt="..." />
                          Delete Post
                        </button>
                      </li>
                    </ul>
                  </div>
                </div>
              )}
            </div>
            <div className="post-description">
              <Link
                to={`/post/${feed.linkTitle}/${feed.slug}`}
                style={{ textDecoration: "none" }}
              >
                <h4>{feed.title}</h4>
              </Link>

              <Linkify
                componentDecorator={(decoratedHref, decoratedText, key) => (
                  <a target="blank" href={decoratedHref} key={key}>
                    {decoratedText}
                  </a>
                )}
              >
                {feed.description}
              </Linkify>
            </div>
          </div>
          {feed.media && feed.media.length > 1 && (
            <div className="post-images">
              <div className="slider-1">
                {/* <div className="owl-carousel owl-theme"> */}
                <Carousel
                  navButtonsAlwaysVisible={feed.media.length > 1}
                  fullHeightHover={false}
                  indicators={false}
                >
                  {feed.media &&
                    feed.media.length > 0 &&
                    feed.media.map((media, index) => (
                      <ReactCarousel key={index} media={media} />
                    ))}
                </Carousel>
              </div>
            </div>
          )}

          {feed.media && feed.media.length === 1 && (
            <div className="post-images">
              {/* <div className="owl-carousel owl-theme"> */}

              {feed.media &&
                feed.media.length > 0 &&
                feed.media.map((media, index) => (
                  <div className="slider-1">
                    {media.type === "image" ? (
                      <div class="item">
                        <div style={{ height: "auto", overflow: "inherit" }}>
                          <img
                            src={`${BASE_URL}${media.path}`}
                            alt="..."
                            style={{ width: "100%" }}
                          />
                        </div>
                      </div>
                    ) : (
                      <div class="item">
                        <div
                          style={{
                            height: "auto",
                            overflow: "visible",
                          }}
                        >
                          <VisibilitySensor
                            onChange={(isVisible) => setIsVisible(isVisible)}
                          >
                            <video
                              controls
                              ref={videoRef}
                              style={{ width: "100%", maxHeight: "50vh" }}
                              controlsList="nodownload"
                            >
                              <source
                                src={`${BASE_URL}${media.path}`}
                                type="video/mp4"
                              />
                              Your browser does not support the video tag.
                            </video>
                          </VisibilitySensor>
                        </div>
                      </div>
                    )}
                  </div>
                ))}
            </div>
          )}

          <div className="post-action-bar">
            <div className="post-action-bar-in">
              <div
                className="post-action-handlers"
                onClick={() => likeHandler(feed)}
              >
                {feed.likes.includes(user._id.toString()) ? (
                  <img src="/images/thumbs-up-liked.svg" alt="..." />
                ) : (
                  <img src="/images/thumbs-up.svg" alt="..." />
                )}

                <span>{feed.likeCount}</span>
              </div>
              <div
                className="post-action-handlers"
                onClick={() => commentHandler(feed._id)}
              >
                <img src="/images/message-dots-circle.svg" alt="..." />
                <span>{feed.commentCount} Comments</span>
              </div>
              <div
                className="post-action-handlers"
                onClick={() => shareHandler(feed.link)}
              >
                <img src="/images/share-06.svg" alt="..." />
                <span>Share</span>
              </div>
            </div>
          </div>
          {feed.commentShow && (
            <Comments
              feedId={feed._id}
              image={user.image}
              addCommentHandler={addCommentHandler}
              comments={[...feed.comments].reverse()}
              commentCount={feed.commentCount}
              replyHandler={replyHandler}
              commentDeleteHandler={commentDeleteHandler}
              deleteReplyHandler={deleteReplyHandler}
              commentEditHandler={commentEditHandler}
              editingComment={editingComment}
              editReplyHandler={editReplyHandler}
              showMoreComments={showMoreComments}
              showLessComments={showLessComments}
              showMoreDisable={showMoreDisable}
              showLessDisable={showLessDisable}
              limit={limit}
              textRef={textRef}
              replyRef={replyRef}
            />
          )}

          {feed && (
            <EditPostModal
              open={open}
              setOpen={setOpen}
              handleClickOpen={handleClickOpen}
              handleClose={handleClose}
              feed={feed}
              editPostClickHandler={editPostClickHandler}
            />
          )}

          <AlertDialogSlide
            open={deleteOpen}
            setOpen={setDeleteOpen}
            deleteHandler={onConfirmation}
          >
            Are you Sure? You want to delete This Feed?
          </AlertDialogSlide>
        </div>
      )}
    </>
  );
};

export default FeedList;

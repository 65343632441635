// types
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
// import axios from "axios";
// import { BASE_URL } from "config";
// import { toast } from "react-toastify";

// const USER_BASE_URL = `${BASE_URL}api/v2/user`;

// initial state
const initialState = {
  token: localStorage.getItem("userToken")
    ? localStorage.getItem("userToken")
    : null,
  user: localStorage.getItem("webUser")
    ? JSON.parse(localStorage.getItem("webUser"))
    : null,
};

const user = createSlice({
  name: "user",
  initialState,
  reducers: {
    setTokenAndUser: (state, action) => {
      console.log(action.payload);
      localStorage.setItem("userToken", action.payload.token);
      localStorage.setItem("webUser", JSON.stringify(action.payload.user));
      state.token = action.payload.token;
      state.user = action.payload.user;
    },
    logout: (state, action) => {
      console.log(action.payload);
      localStorage.removeItem("userToken");
      localStorage.removeItem("webUser");
      state.token = null;
      state.user = null;
    },
  },
  extraReducers: {},
});

export default user.reducer;

export const { setTokenAndUser, logout } = user.actions;

import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useLocation } from "react-router";
import { Get } from "../../api";
import parse from "html-react-parser";
import SideBar from "../../Components/SideBar";
import { Link } from "react-router-dom";

const useQuery = () => {
  return new URLSearchParams(useLocation().search);
};

const CMS = () => {
  const query = useQuery();
  const page = query.get("page");

  const [content, setContent] = useState("");

  const { token } = useSelector((state) => state.user);

  useEffect(() => {
    (async () => {
      const { data } = await Get(`api/v2/app/cms?cms=${page}`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      });

      if (data) {
        setContent(data.CMSPage.description);
      }
    })();
  }, [page, token]);

  return (
    <div class="container-relative">
      <SideBar />
      <div class="in-container">
        <div class="page-head mb-4">
          <div class="d-flex align-items-center">
            <Link to="/settings" class="btn btn-icon">
              <img src="/images/arrow-left.svg" alt="..." />
            </Link>
            <h4 class="ms-3">
              {page.toLowerCase() === "privacy policy"
                ? "Privacy Policy"
                : "Terms & Conditions"}
            </h4>
          </div>
        </div>
        <div class="card t-and-c-wrap">
          <div class="paragraph-area">{parse(content)}</div>
        </div>
      </div>
    </div>
  );
};

export default CMS;

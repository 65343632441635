import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useLocation } from "react-router-dom";
import { Get } from "../api";
import { BASE_URL } from "../constants";
import { setLocation } from "../store/reducers/currentLocation";
import { setHeaderStatus } from "../store/reducers/header";

const MainHeader = () => {
  const { user, token } = useSelector((state) => state.user);
  const { status } = useSelector((state) => state.header);
  const [locations, setLocations] = useState([]);

  const dispatch = useDispatch();

  const iconClickHandler = () => {
    dispatch(setHeaderStatus());
  };

  const handleStateChange = (e) => {
    if (e.target.value.toLowerCase() !== "select state") {
      dispatch(setLocation({ location: e.target.value }));
    } else {
      dispatch(setLocation({ location: null }));
    }
  };

  const location = useLocation();

  useEffect(() => {
    if (token) {
      (async () => {
        const response = await Get(`api/v2/app/location`, {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        });

        if (response.data.locations.length > 0) {
          setLocations([...response.data.locations]);
          // setCurrentLocation(response.data.locations[0]._id);
        }
      })();
    }
  }, [user, token, setLocations]);
  console.log(locations);

  return (
    <header>
      <nav className="navbar navbar-expand-lg p-0">
        <div className="container">
          <div className="header-in w-100 d-flex align-items-center">
            <Link className="navbar-brand" to="/">
              <img src="/images/logo.svg" alt="..." />
            </Link>
            <button
              className="navbar-toggler"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#navbarSupportedContent"
              aria-controls="navbarSupportedContent"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              <span className="navbar-toggler-icon"></span>
            </button>
            <div
              className="collapse navbar-collapse"
              id="navbarSupportedContent"
            >
              {location.pathname === "/" && (
                <div class="select-state d-flex align-items-center">
                  <div class="form-group m-0">
                    <select
                      className="form-control"
                      id="state"
                      // value={currentState}
                      label="state"
                      onChange={(e) => handleStateChange(e)}
                    >
                      <option selected>Select state</option>
                      {locations &&
                        locations.length > 0 &&
                        locations.map((_location, index) => (
                          <option key={index} id="state" value={_location._id}>
                            {_location.state}
                          </option>
                        ))}
                    </select>
                  </div>
                </div>
              )}

              <div className="profile-header d-flex align-items-center ms-auto">
                <figure className="avatar">
                  {user.image ? (
                    <img src={`${BASE_URL}${user.image}`} alt="..." />
                  ) : (
                    <img src="/images/default.jpeg" alt="..." />
                  )}
                </figure>
                <figcaption className="ms-2">
                  <figcaption className="ms-2">{user.fullName}</figcaption>
                </figcaption>
              </div>
              <div
                className={
                  status
                    ? "menu-mobile btn btn-icon active"
                    : "menu-mobile btn btn-icon"
                }
                onClick={iconClickHandler}
              >
                <i className="ri-menu-3-line"></i>
                <i className="ri-close-fill"></i>
              </div>
            </div>
          </div>
        </div>
      </nav>
    </header>
  );
};

export default MainHeader;

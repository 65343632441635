import React from "react";
import { Post } from "../../api";
import * as Yup from "yup";
import { Formik } from "formik";
import { useLocation, useNavigate } from "react-router";
import { toast } from "react-toastify";

const useQuery = () => {
  return new URLSearchParams(useLocation().search);
};

const ChangePassword = () => {
  const query = useQuery();
  const mobile = query.get("mobile");
  const validPassRegex =
    /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/;
  const navigate = useNavigate();

  return (
    <div className="auth-form card-shadow p-5">
      <div className="login-form-head">
        <img src="/images/otp.svg" />
        <h4>Reset your password</h4>
        <p>Please enter your new password</p>
      </div>
      <div className="form-box">
        <Formik
          initialValues={{
            password: "",
            confirmPassword: "",
          }}
          enableReinitialize
          validationSchema={Yup.object().shape({
            password: Yup.string()
              .required("Password required")
              .trim()
              .max(25)
              .matches(
                validPassRegex,
                "Password Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and One Special Case Character"
              ),
            confirmPassword: Yup.string()
              .required("Confirm password required")
              .trim()
              .oneOf([Yup.ref("password"), null], "Should match password"),
          })}
          onSubmit={async (values, { setErrors, setStatus, setSubmitting }) => {
            // if()
            try {
              const response = await Post(
                { mobile, password: values.password },
                "api/v2/app/user/changePassword",
                {
                  headers: {
                    "Content-Type": "application/json",
                    // Authorization: `Bearer ${token}`,
                  },
                }
              );
              console.log(response);
              if (response && response.data && response.data.status) {
                toast.success(response.data.message);
                navigate("../login", { replace: true });
                // console.log(response.data.user);
                // dispatch(
                //   setTokenAndUser({
                //     token: response.data.token,
                //     user: response.data.user,
                //   })
                // );
              }
              setStatus({ success: false });
              setSubmitting(false);
            } catch (err) {
              setStatus({ success: false });
              setErrors({ submit: err.message });
              setSubmitting(false);
            }
          }}
        >
          {({
            errors,
            handleBlur,
            handleChange,
            handleSubmit,
            isSubmitting,
            touched,
            values,
            setFieldValue,
          }) => (
            <form
              className="needs-validation"
              noValidate
              onSubmit={handleSubmit}
            >
              <div className="form-group">
                <div className="input-group has-validation">
                  <span className="input-group-text" id="inputGroupPrepend">
                    <img src="/images/lock-01.svg" />
                  </span>
                  <input
                    type="password"
                    className="form-control"
                    id="password"
                    name="password"
                    aria-describedby="inputGroupPrepend"
                    required=""
                    placeholder="Enter new password"
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                  {errors.password && (
                    <div className="invalid-feedback-custom">
                      {errors.password}
                    </div>
                  )}
                </div>
              </div>
              <div className="form-group">
                <div className="input-group has-validation">
                  <span className="input-group-text" id="inputGroupPrepend">
                    <img src="/images/lock-01.svg" />
                  </span>
                  <input
                    type="password"
                    className="form-control"
                    id="confirmPassword"
                    name="confirmPassword"
                    aria-describedby="inputGroupPrepend"
                    required=""
                    placeholder="Confirm password"
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                  {errors.confirmPassword && (
                    <div className="invalid-feedback-custom">
                      {errors.confirmPassword}
                    </div>
                  )}
                </div>
              </div>
              <button className="btn btn-blue w-100" type="submit">
                Submit
              </button>
            </form>
          )}
        </Formik>
      </div>
    </div>
  );
};

export default ChangePassword;

import { createSlice } from "@reduxjs/toolkit";

export const location = createSlice({
  name: "location",
  initialState: {
    location: null,
  },
  reducers: {
    setLocation: (state, action) => {
      state.location = action.payload.location;
    },
  },
});

// Action creators are generated for each case reducer function
export const { setLocation } = location.actions;

export default location.reducer;

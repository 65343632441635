import axios from "axios";
import { BASE_URL } from "./constants";
import { toast } from "react-toastify";

export const Get = async (path, config) => {
  try {
    const response = await axios.get(`${BASE_URL}${path}`, config);
    if (response.data.status) {
      return response;
    } else {
      toast.error(response.data.message);
    }
  } catch (err) {
    toast.error(err.response.data.message || err.message);
  }
};

export const Post = async (data, path, config) => {
  try {
    const response = await axios.post(`${BASE_URL}${path}`, data, config);
    if (response.data.status) {
      return response;
    } else {
      toast.error(response.data.message);
      return response;
    }
  } catch (err) {
    toast.error(err.response.data.message || err.message);
    return err.response;
  }
};

export const Patch = async (data, path, config) => {
  try {
    const response = await axios.patch(`${BASE_URL}${path}`, data, config);
    if (response.data.status) {
      return response;
    } else {
      toast.error(response.data.message);
    }
  } catch (err) {
    toast.error(err.response.data.message || err.message);
  }
};

export const Delete = async (path, config) => {
  try {
    const response = await axios.delete(`${BASE_URL}${path}`, config);
    if (response.data.status) {
      toast.success(response.data.message);
      return { status: true };
    } else {
      toast.error(response.data.message);
    }
  } catch (err) {
    toast.error(err.response.data.message || err.message);
  }
};

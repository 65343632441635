import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router";
import { Link } from "react-router-dom";
import { Get, Post } from "../../api";
import Connections from "../../Components/Connections";
import SideBar from "../../Components/SideBar";
import { BASE_URL } from "../../constants";

const FollowFollowing = () => {
  const { userName, tab } = useParams();

  const [followers, setFollowers] = useState([]);
  const [following, setFollowing] = useState([]);
  const [currentUsername, setCurrentUsername] = useState(null);

  const [currentList, setCurrentList] = useState(tab || "followers");

  const { token } = useSelector((state) => state.user);

  const followHandler = async (userId) => {
    let connectionData = [];
    if (currentList === "followers") {
      connectionData = [...followers];
    } else {
      connectionData = [...following];
    }

    for (const _user of connectionData) {
      if (_user._id.toString() === userId) {
        _user.followedByMe = !_user.followedByMe;
      }
    }
    if (currentList === "followers") {
      setFollowers([...connectionData]);
    } else {
      setFollowing([...connectionData]);
    }
    // setThisUser((prevState) => ({
    //   ...prevState,
    //   followed: !prevState.followed,
    // }));
    const { data } = await Post(
      { followingId: userId },
      "api/v2/app/user/follow",
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    );
  };

  useEffect(() => {
    (async () => {
      const { data } = await Get(`api/v2/app/user/followers-name/${userName}`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      });
      if (data) {
        setCurrentUsername(data.userDetails.fullName);
        setFollowers(data.userDetails.followersDetails);
      }
    })();
    (async () => {
      const { data } = await Get(
        `api/v2/app/user/followings-name/${userName}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      if (data) {
        // console.log(data);
        // console.log(data.userDetails.followingsDetails);
        setFollowing(data.userDetails.followingDetails);
      }
    })();
  }, [token, userName]);

  //   console.log({ followers });
  //   console.log({ following });

  const findData = () => {
    return currentList === "followers" ? followers : following;
  };

  return (
    <div className="container-relative">
      <SideBar />
      <div className="in-container">
        <div className="page-head mb-4">
          <div className="d-flex align-items-center">
            <Link to={`/${userName}`} className="btn btn-icon">
              <img src="/images/arrow-left.svg" />
            </Link>
            {currentUsername && <h4 className="ms-3">{currentUsername}</h4>}
          </div>
        </div>
        <div className="ff-list-wrap">
          <div className="card">
            <ul className="nav nav-pills" id="pills-tab" role="tablist">
              <li className="nav-item" role="presentation">
                <button
                  className={`nav-link ${
                    currentList === "followers" ? "active" : ""
                  } `}
                  // id="follower-tab"
                  // data-bs-toggle="pill"
                  // data-bs-target="#follower"
                  type="button"
                  // role="tab"
                  // aria-controls="follower"
                  // aria-selected="true"
                  onClick={() => setCurrentList("followers")}
                >
                  Followers
                </button>
              </li>
              <li className="nav-item" role="presentation">
                <button
                  className={`nav-link ${
                    currentList === "followers" ? "" : "active"
                  } `}
                  // id="following-tab"
                  // data-bs-toggle="pill"
                  // data-bs-target="#following"
                  type="button"
                  // role="tab"
                  // aria-controls="following"
                  // aria-selected="false"
                  onClick={() => setCurrentList("following")}
                >
                  Following
                </button>
              </li>
            </ul>

            <Connections
              data={findData}
              followHandler={followHandler}
              currentList={currentList}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default FollowFollowing;

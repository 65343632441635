import React from "react";
import { Outlet } from "react-router";

import SharedFooter from "./SharedFooter";
import SharedHeader from "./SharedHeader";

const SharedLayout = () => {
  return (
    <div className="wraper-main">
      <SharedHeader />
      <div className="site-container">
        <div className="container">
          <Outlet />
        </div>
      </div>
      <SharedFooter />
    </div>
  );
};

export default SharedLayout;

import React from "react";
import { Post } from "../../api";
import * as Yup from "yup";
import { Formik } from "formik";
import { useNavigate } from "react-router";
import { toast } from "react-toastify";

const ForgotPassword = () => {
  const navigate = useNavigate();
  const phoneRegExp =
    /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;
  return (
    <div className="auth-form card-shadow p-5">
      <div className="login-form-head">
        <img src="/images/forgot.svg" alt="..." />
        <h4>Forgot Password</h4>
        <p>Please enter your mobile number to receive a verification code</p>
      </div>
      <div className="form-box">
        <Formik
          initialValues={{
            mobile: "",
          }}
          enableReinitialize
          validationSchema={Yup.object().shape({
            mobile: Yup.string()
              .required("Mobile number required")
              .matches(phoneRegExp, "Invalid mobile number")
              .max(10),
          })}
          onSubmit={async (values, { setErrors, setStatus, setSubmitting }) => {
            try {
              const response = await Post(
                values,
                "api/v2/app/user/forgotPassword",
                {
                  headers: {
                    "Content-Type": "application/json",
                    // Authorization: `Bearer ${token}`,
                  },
                }
              );
              if (response && response.data.status) {
                // console.log(response.data.user);
                navigate(`../verification?mobile=${values.mobile}&type=reset`, {
                  replace: true,
                });
                toast.success("Otp sent to your mobile number");
              }
              setStatus({ success: false });
              setSubmitting(false);
            } catch (err) {
              setStatus({ success: false });
              setErrors({ submit: err.message });
              setSubmitting(false);
            }
          }}
        >
          {({
            errors,
            handleBlur,
            handleChange,
            handleSubmit,
            isSubmitting,
            touched,
            values,
            setFieldValue,
          }) => (
            <form
              className="needs-validation"
              onSubmit={handleSubmit}
              noValidate
            >
              <div className="form-group">
                <div className="input-group has-validation">
                  <span className="input-group-text" id="inputGroupPrepend">
                    <img src="/images/phone-01.svg" alt="..." />
                  </span>
                  <input
                    type="text"
                    className="form-control"
                    id="mobile"
                    aria-describedby="inputGroupPrepend"
                    required=""
                    placeholder="Mobile number"
                    onBlur={handleBlur}
                    value={values.mobile}
                    name="mobile"
                    onChange={handleChange}
                    error={touched.mobile && errors.mobile}
                  />
                  {errors.mobile && (
                    <div className="invalid-feedback-custom">
                      {errors.mobile}
                    </div>
                  )}
                </div>
              </div>
              <button className="btn btn-blue w-100" type="submit">
                Submit
              </button>
            </form>
          )}
        </Formik>
      </div>
    </div>
  );
};

export default ForgotPassword;

import moment from "moment";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { Get } from "../../api";
import SideBar from "../../Components/SideBar";

const Notification = () => {
  const { token } = useSelector((state) => state.user);

  const [notifications, setNotification] = useState([]);

  useEffect(() => {
    (async () => {
      const { data } = await Get("api/v2/app/notification", {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      });
      setNotification(data.notifications);
    })();
  }, [token]);

  const notificationObj = {
    follow: "followed you",
    like: "liked your post",
    comment: "commented on your post.",
    reply: "replied to your comment.",
  };

  return (
    <div class="container-relative">
      <SideBar />
      <div class="in-container">
        <div class="page-head mb-4">
          <div class="d-flex align-items-center">
            <Link to="/" class="btn btn-icon">
              <img src="/images/arrow-left.svg" alt="..." />
            </Link>
            <h4 class="ms-3">Notifications</h4>
          </div>
        </div>
        <div class="notifications-wrap card">
          {notifications.length > 0 ? (
            <ul>
              {notifications.map((notification) => (
                <li>
                  <div class="row align-items-center">
                    <div class="col">
                      <div class="post-profile">
                        <figure class="avatar">
                          <Link href="javascript:;">
                            <img src="/images/default.jpeg" />
                          </Link>
                        </figure>
                        <figcaption>
                          <div>
                            <h4>
                              <a href="javascript:;">
                                {notification.ownerName}
                              </a>{" "}
                              {notificationObj[notification.text]}
                              {/* <a class="link-blue" href="javascript:;">
                                post
                              </a> */}
                            </h4>
                          </div>
                        </figcaption>
                      </div>
                    </div>
                    <div class="ms-auto w-auto">
                      <time>{moment(notification.createdAt).fromNow()}</time>
                    </div>
                  </div>
                </li>
              ))}
            </ul>
          ) : (
            <div class="no-post">
              <div class="no-post-in text-center">
                <figure>
                  <img src="/images/bell.svg" />
                </figure>
                <h4>No notification right now!</h4>
                <p>You're up-to-date! Would work well</p>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Notification;

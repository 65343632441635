import React, { useState } from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import { Post } from "../../api";
import SideBar from "../../Components/SideBar";

const initialState = {
  rating: 0,
  description: "",
};

const Feedback = () => {
  const [feedbackState, setFeedbackState] = useState(initialState);

  const { token } = useSelector((state) => state.user);

  const handleSubmit = async (e) => {
    e.preventDefault();

    const { data } = await Post(feedbackState, `api/v2/app/user-feedback`, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    });
    if (data.status) {
      toast.success(data.message);
    }
    setFeedbackState(initialState);
  };

  return (
    <div className="container-relative">
      <SideBar />
      <div className="in-container">
        <div className="page-head mb-4">
          <div className="d-flex align-items-center">
            <Link to="/settings" className="btn btn-icon">
              <img src="/images/arrow-left.svg" alt="..." />
            </Link>
            <h4 className="ms-3">Feedback</h4>
          </div>
        </div>
        <div className="card verified-wrap">
          <div className="verified-wrap-in">
            <div className="verified-head mb-3">
              <figure className="mb-3">
                <img src="/images/feedback.svg" />
              </figure>
              <figcaption>
                <h4>Give us rating</h4>
              </figcaption>
            </div>
            <form onSubmit={(e) => handleSubmit(e)}>
              <div className="rate">
                <input
                  type="radio"
                  id="star1"
                  name="rating"
                  value="5"
                  onChange={(e) =>
                    setFeedbackState((prevState) => ({
                      ...prevState,
                      rating: e.target.value,
                    }))
                  }
                />
                <label htmlFor="star1" title="text">
                  <span>Outstanding</span>
                </label>
                <input
                  type="radio"
                  id="star2"
                  name="rating"
                  value="4"
                  onChange={(e) =>
                    setFeedbackState((prevState) => ({
                      ...prevState,
                      rating: e.target.value,
                    }))
                  }
                />
                <label htmlFor="star2" title="text">
                  <span>Excellent</span>
                </label>
                <input
                  type="radio"
                  id="star3"
                  name="rating"
                  value="3"
                  onChange={(e) =>
                    setFeedbackState((prevState) => ({
                      ...prevState,
                      rating: e.target.value,
                    }))
                  }
                />
                <label htmlFor="star3" title="text">
                  <span>Good</span>
                </label>
                <input
                  type="radio"
                  id="star4"
                  name="rating"
                  value="2"
                  onChange={(e) =>
                    setFeedbackState((prevState) => ({
                      ...prevState,
                      rating: e.target.value,
                    }))
                  }
                />
                <label htmlFor="star4" title="text">
                  <span>Average</span>
                </label>
                <input
                  type="radio"
                  id="star5"
                  name="rating"
                  value="1"
                  onChange={(e) =>
                    setFeedbackState((prevState) => ({
                      ...prevState,
                      rating: e.target.value,
                    }))
                  }
                />
                <label htmlFor="star5" title="text">
                  <span>Poor</span>
                </label>
              </div>
              <div className="form-group">
                <textarea
                  className="form-control"
                  placeholder="Write your experience..."
                  style={{ height: "120px" }}
                  id="description"
                  name="description"
                  onChange={(e) =>
                    setFeedbackState((prevState) => ({
                      ...prevState,
                      description: e.target.value,
                    }))
                  }
                ></textarea>
              </div>
              <button className="btn btn-blue w-100" type="submit">
                Submit
              </button>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Feedback;

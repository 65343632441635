import React, { useState } from "react";
import { useEffect } from "react";
import { useParams } from "react-router";
import Carousel from "react-material-ui-carousel";
import { Link } from "react-router-dom";
import { Get } from "../../api";
import { BASE_URL } from "../../constants";

const SharedProfile = () => {
  const { name, id } = useParams();
  const [feeds, setFeeds] = useState([]);
  const [thisUser, setThisUser] = useState({});
  const [loader, setLoader] = useState(true);

  useEffect(() => {
    (async () => {
      setLoader(true);
      const { data } = await Get(`api/v2/feed/user/${id}`, {
        headers: {
          "Content-Type": "application/json",
        },
      });
      console.log(data);
      setFeeds(data.feeds);
      setThisUser(data.user);
      setLoader(false);
    })();
  }, [id]);

  const dateFormat = (date) => {
    const monthNames = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];
    const dateTimestamp = new Date(date);
    return `${
      monthNames[dateTimestamp.getMonth()]
    } ${dateTimestamp.getDate()} ${dateTimestamp.getFullYear()}`;
  };

  return (
    <>
      {!loader && thisUser && (
        <div className="user-page mb-4  ">
          <figure>
            {thisUser && thisUser.image ? (
              <img src={`${BASE_URL}${thisUser.image}`} alt="..." />
            ) : (
              <img src="/images/default.jpeg" alt="..." />
            )}
          </figure>
          <h4>{thisUser.fullName}</h4>
        </div>
      )}
      {!loader && feeds.length > 0 && (
        <div class="post-outer">
          {feeds.map((feed, index) => (
            <div class="post-inner-box" key={index}>
              <div class="post-head">
                <figure>
                  {thisUser && thisUser.image ? (
                    <img src={`${BASE_URL}${thisUser.image}`} alt="..." />
                  ) : (
                    <img src="/images/default.jpeg" alt="..." />
                  )}
                </figure>
                <figcaption>
                  <div class="user-name-info">
                    <h4>{thisUser.fullName}</h4>
                    <p>{dateFormat(feed.createdAt)}</p>
                  </div>
                </figcaption>
              </div>
              <div class="post-video-photo">
                <div
                  id="carouselExampleControls"
                  class="carousel slide"
                  data-bs-ride="carousel"
                  data-interval="false"
                >
                  <div class="carousel-inner">
                    <Carousel
                      navButtonsAlwaysVisible={feed.media.length > 0}
                      fullHeightHover={false}
                      indicators={false}
                    >
                      {feed.media.length > 0 &&
                        feed.media.map((media, index) => (
                          <div className="carousel-item" key={index}>
                            {media.type.toLowerCase() === "image" ? (
                              <img
                                src={`${BASE_URL}${media.path}`}
                                className="d-block w-100"
                                alt="..."
                              />
                            ) : (
                              <video controls autoPlay>
                                <source
                                  src={`${BASE_URL}${media.path}`}
                                  type="video/mp4"
                                />
                                Your browser does not support the video tag.
                              </video>
                            )}
                          </div>
                        ))}
                    </Carousel>
                  </div>
                </div>
              </div>
              <div class="post-desc">
                <h4>{feed.title}</h4>
                <p>{feed.description}</p>
                <Link
                  to={`/post/${feed.linkTitle}/${feed.slug}`}
                  class="btn btn-blue mt-2"
                >
                  View more <i class="ri-arrow-right-line"></i>
                </Link>
              </div>
            </div>
          ))}
        </div>
      )}
    </>
  );
};

export default SharedProfile;

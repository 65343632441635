import React from "react";

const Loader = () => {
  return (
    <div className="loader-container">
      <img src="/images/loader.gif" className="spinner" alt="..." />
    </div>
  );
};

export default Loader;

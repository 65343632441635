import SharedLayout from "../Components/SharedLayout";
import SharedFeed from "../Pages/Shared/SharedFeed";
import SharedProfile from "../Pages/Shared/SharedProfile";

const SharedRoutes = () => {
  return {
    path: "/",
    element: <SharedLayout />,
    children: [
      {
        path: "post/:title/:slug",
        element: <SharedFeed />,
      },
      {
        path: "user/:name/:id",
        element: <SharedProfile />,
      },
    ],
  };
};

export default SharedRoutes;

import React from "react";

const SharedHeader = () => {
  return (
    <header>
      <nav className="navbar navbar-expand-lg p-0">
        <div className="container">
          <div className="header-in w-100 d-flex align-items-center">
            <a className="navbar-brand" href="#">
              <img src="/images/logo.svg" />
            </a>
            <button
              className="navbar-toggler"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#navbarSupportedContent"
              aria-controls="navbarSupportedContent"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              <span className="navbar-toggler-icon"></span>
            </button>
            <div
              className="collapse navbar-collapse"
              id="navbarSupportedContent"
            >
              <ul className="navbar-nav ms-auto">
                <li className="nav-item">
                  <a className="nav-link" aria-current="page" href="#">
                    <img src="/images/google.svg" />
                  </a>
                </li>
                <li className="nav-item">
                  <a className="nav-link" aria-current="page" href="#">
                    <img src="/images/apple.svg" />
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </nav>
    </header>
  );
};

export default SharedHeader;

import React from "react";
import { Link, useNavigate } from "react-router-dom";
import { Formik } from "formik";
import * as Yup from "yup";
import { Post } from "../../api";

const Register = () => {
  const phoneRegExp =
    /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;
  const validPassRegex =
    /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/;

  const navigate = useNavigate();

  return (
    <div className="auth-form card-shadow p-5">
      <div className="login-form-head">
        <img src="/images/logo-icon.svg" alt="..." />
        <h4>Create your account</h4>
      </div>
      <div className="form-box">
        <Formik
          initialValues={{
            fullName: "",
            mobile: "",
            password: "",
            confirmPassword: "",
          }}
          enableReinitialize
          validationSchema={Yup.object().shape({
            fullName: Yup.string()
              .required("Full name required")
              .trim()
              .min(2, "Min length two"),
            mobile: Yup.string()
              .required("Mobile number required")
              .matches(phoneRegExp, "Invalid mobile number")
              .min(10)
              .max(10),
            password: Yup.string()
              .required("Password required")
              .matches(
                validPassRegex,
                "Password Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and One Special Case Character"
              ),
            confirmPassword: Yup.string()
              .required("Confirm password required")
              .trim()
              .oneOf([Yup.ref("password"), null], "Should match password"),
          })}
          onSubmit={async (values, { setErrors, setStatus, setSubmitting }) => {
            try {
              const response = await Post(values, "api/v2/app/user/signup", {
                headers: {
                  "Content-Type": "application/json",
                  // Authorization: `Bearer ${token}`,
                },
              });
              console.log(response);
              if (response && response.data && response.data.status) {
                navigate(`../verification?mobile=${values.mobile}`, {
                  replace: true,
                });
              }
              setStatus({ success: false });
              setSubmitting(false);
            } catch (err) {
              setStatus({ success: false });
              setErrors({ submit: err.message });
              setSubmitting(false);
            }
          }}
        >
          {({
            errors,
            handleBlur,
            handleChange,
            handleSubmit,
            isSubmitting,
            touched,
            values,
            setFieldValue,
          }) => (
            <form
              className="needs-validation"
              noValidate
              onSubmit={handleSubmit}
            >
              <div className="form-group">
                <div className="input-group has-validation">
                  <span className="input-group-text" id="inputGroupPrepend">
                    <img src="/images/user-01.svg" />
                  </span>
                  <input
                    type="text"
                    className="form-control"
                    id="fullName"
                    name="fullName"
                    aria-describedby="inputGroupPrepend"
                    required=""
                    placeholder="FullName"
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                  {errors.fullName && touched.fullName && (
                    <div className="invalid-feedback-custom">
                      {errors.fullName}
                    </div>
                  )}
                </div>
              </div>
              <div className="form-group">
                <div className="input-group has-validation">
                  <span className="input-group-text" id="inputGroupPrepend">
                    <img src="/images/phone-01.svg" alt="..." />
                  </span>
                  <input
                    type="text"
                    className="form-control"
                    id="mobile"
                    aria-describedby="inputGroupPrepend"
                    required=""
                    placeholder="Mobile number"
                    onBlur={handleBlur}
                    value={values.mobile}
                    name="mobile"
                    onChange={handleChange}
                    // error={touched.mobile && errors.mobile}
                  />
                  {errors.mobile && touched.mobile && (
                    <div className="invalid-feedback-custom">
                      {errors.mobile}
                    </div>
                  )}
                </div>
              </div>
              <div className="form-group">
                <div className="input-group has-validation">
                  <span className="input-group-text" id="inputGroupPrepend">
                    <img src="/images/lock-01.svg" alt="..." />
                  </span>
                  <input
                    type="password"
                    className="form-control"
                    id="password"
                    name="password"
                    aria-describedby="inputGroupPrepend"
                    required=""
                    placeholder="Password"
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                  {errors.password && touched.password && (
                    <div className="invalid-feedback-custom">
                      {errors.password}
                    </div>
                  )}
                </div>
              </div>
              <div className="form-group">
                <div className="input-group has-validation">
                  <span className="input-group-text" id="inputGroupPrepend">
                    <img src="/images/lock-01.svg" alt="..." />
                  </span>
                  <input
                    type="password"
                    className="form-control"
                    id="confirmPassword"
                    name="confirmPassword"
                    aria-describedby="inputGroupPrepend"
                    required=""
                    placeholder="Confirm password"
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                  {errors.confirmPassword && touched.confirmPassword && (
                    <div className="invalid-feedback-custom">
                      {errors.confirmPassword}
                    </div>
                  )}
                </div>
              </div>
              <button className="btn btn-blue w-100" type="submit">
                Submit
              </button>
              <div className="forgot-link text-center mt-4">
                Already a user? <Link to="/login">Sign in</Link>
              </div>
            </form>
          )}
        </Formik>
      </div>
    </div>
  );
};

export default Register;

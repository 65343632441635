import React from "react";
import { Formik } from "formik";
import * as Yup from "yup";
import { Post } from "../../api";
import { setTokenAndUser } from "../../store/reducers/user";
import { useDispatch } from "react-redux";
import { Link, useNavigate } from "react-router-dom";

const Login = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const phoneRegExp =
    /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;

  return (
    <div className="login-container">
      <div className="row align-items-center justify-content-center">
        <div className="col-md-7">
          <div className="login-info">
            <div className="login-head-info">
              <h4>Lorem Ipsum is simply dummy</h4>
              <p>
                Lorem Ipsum is simply dummy text of the printing and typesetting
                industry.
              </p>
            </div>
            <div className="login-side-img">
              <img src="/images/login.png" alt="..." />
            </div>
          </div>
        </div>
        <div className="col-md-5">
          <div className="login-form card-shadow p-5">
            <div className="login-form-head">
              <img src="/images/logo-icon.svg" alt="..." />
              <h4>Login your account</h4>
            </div>

            <div className="form-box">
              <Formik
                initialValues={{
                  mobile: "",
                  password: "",
                }}
                enableReinitialize
                validationSchema={Yup.object().shape({
                  mobile: Yup.string()
                    .required("Mobile number required")
                    .matches(phoneRegExp, "Invalid mobile number")
                    .max(10),
                  password: Yup.string().required("Password required"),
                })}
                onSubmit={async (
                  values,
                  { setErrors, setStatus, setSubmitting }
                ) => {
                  try {
                    const response = await Post(
                      values,
                      "api/v2/app/user/login",
                      {
                        headers: {
                          "Content-Type": "application/json",
                          // Authorization: `Bearer ${token}`,
                        },
                      }
                    );
                    if (response && response.data.token) {
                      // console.log(response.data.user);
                      dispatch(
                        setTokenAndUser({
                          token: response.data.token,
                          user: response.data.user,
                        })
                      );
                    }
                    if (response && response.data.isMobileVerified === false) {
                      // console.log(response.data.user);
                      navigate(`../verification?mobile=${values.mobile}`);
                    }
                    setStatus({ success: false });
                    setSubmitting(false);
                  } catch (err) {
                    setStatus({ success: false });
                    setErrors({ submit: err.message });
                    setSubmitting(false);
                  }
                }}
              >
                {({
                  errors,
                  handleBlur,
                  handleChange,
                  handleSubmit,
                  isSubmitting,
                  touched,
                  values,
                  setFieldValue,
                }) => (
                  <form
                    className="needs-validation"
                    noValidate
                    onSubmit={handleSubmit}
                  >
                    <div className="form-group">
                      <div className="input-group has-validation">
                        <span
                          className="input-group-text"
                          id="inputGroupPrepend"
                        >
                          <img src="/images/phone-01.svg" alt="..." />
                        </span>
                        <input
                          type="text"
                          className="form-control"
                          id="mobile"
                          aria-describedby="inputGroupPrepend"
                          required=""
                          placeholder="Mobile number"
                          onBlur={handleBlur}
                          value={values.mobile}
                          name="mobile"
                          onChange={handleChange}
                          // error={touched.mobile && errors.mobile}
                        />
                        {errors.mobile && touched.mobile && (
                          <div className="invalid-feedback-custom">
                            {errors.mobile}
                          </div>
                        )}
                      </div>
                    </div>
                    <div className="form-group">
                      <div className="input-group has-validation">
                        <span
                          className="input-group-text"
                          id="inputGroupPrepend"
                        >
                          <img src="/images/lock-01.svg" alt="..." />
                        </span>
                        <input
                          type="password"
                          className="form-control"
                          id="password"
                          aria-describedby="inputGroupPrepend"
                          required=""
                          placeholder="Password"
                          onBlur={handleBlur}
                          value={values.password}
                          name="password"
                          onChange={handleChange}
                          //   error={Boolean(
                          //     touched.validationCustomPassword &&
                          //       errors.validationCustomPassword
                          //   )}
                        />
                        {errors.password && touched.password && (
                          <div className="invalid-feedback-custom">
                            {errors.password}
                          </div>
                        )}
                      </div>
                    </div>
                    <button className="btn btn-blue w-100" type="submit">
                      Sign in
                    </button>
                    <div className="forgot-link text-center mt-2">
                      <Link to="/forgot-password">Forgot Password?</Link>
                    </div>
                    <div className="forgot-link text-center mt-4">
                      Don't have an account? <Link to="/signup">Signup</Link>
                    </div>
                  </form>
                )}
              </Formik>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;

import React from "react";
import { useSelector } from "react-redux";

const PostActionBar = ({
  likeHandler,
  commentHandler,
  shareHandler,
  feed,
  media,
}) => {
  const { user } = useSelector((state) => state.user);

  if (feed && !media) {
    return (
      <div className="post-action-bar-in">
        <div className="post-action-handlers" onClick={() => likeHandler(feed)}>
          {feed.likes && feed.likes.includes(user._id.toString()) ? (
            <img src="/images/thumbs-up-liked.svg" alt="..." />
          ) : (
            <img src="/images/thumbs-up.svg" alt="..." />
          )}
          <span>{feed.likesCount}</span>
        </div>
        <div
          className="post-action-handlers"
          onClick={() => commentHandler(feed._id)}
        >
          <img src="/images/message-dots-circle.svg" alt="..." />
          <span>{feed.commentCount} Comments</span>
        </div>
        <div className="post-action-handlers" onClick={shareHandler}>
          <img src="/images/share-06.svg" alt="..." />
          <span>Share</span>
        </div>
      </div>
    );
  } else if (feed && media) {
    // console.log(media.likes);
    // console.log(user);
    const findUserLike = media.likes.find((_user) => {
      return _user.userId.toString() === user._id.toString();
    });
    return (
      <div className="post-action-bar-in">
        <div
          className="post-action-handlers"
          onClick={() => likeHandler(feed, media)}
        >
          {media.likes && findUserLike ? (
            <img src="/images/thumbs-up-liked.svg" alt="..." />
          ) : (
            <img src="/images/thumbs-up.svg" alt="..." />
          )}
          <span>{media.likesCount}</span>
        </div>
        <div
          className="post-action-handlers"
          onClick={() => commentHandler(feed._id, media._id)}
        >
          <img src="/images/message-dots-circle.svg" alt="..." />
          <span>{media.commentCount} Comments</span>
        </div>
        <div className="post-action-handlers" onClick={shareHandler}>
          <img src="/images/share-06.svg" alt="..." />
          <span>Share</span>
        </div>
      </div>
    );
  }
};

export default PostActionBar;

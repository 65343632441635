import moment from "moment";
import React, { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import { Delete, Get, Patch } from "../../api";
import Comments from "../../Components/Comments";
import AlertDialogSlide from "../../Components/DeleteDilalog";
import EditPostModal from "../../Components/EditPostModal";
import Loader from "../../Components/Loader";
import PostActionBar from "../../Components/PostActionBar";
import SideBar from "../../Components/SideBar";
import VisibilitySensor from "react-visibility-sensor";
import Linkify from "react-linkify";

import { BASE_URL } from "../../constants";

const PostDetail = () => {
  const [loading, setLoading] = useState(true);
  const { token, user } = useSelector((state) => state.user);
  const { socket } = useSelector((state) => state.socket);

  const [editingComment, setEditingComment] = useState(false);
  const [editCurrentComment, setEditCurrentComment] = useState({});

  const [editingReply, setEditingReply] = useState(false);
  const [editCurrentReply, setEditCurrentReply] = useState({});

  const [feed, setFeed] = useState({});
  const [open, setOpen] = useState(false);

  const [feedId, setFeedId] = useState(null);
  const [deleteOpen, setDeleteOpen] = useState(false);

  const textRef = useRef();
  const replyRef = useRef();

  const { postTitle, postSlug } = useParams();
  const videoRef = useRef(null);
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    if (videoRef && videoRef.current) {
      if (isVisible) {
        // videoRef.current.play();
      } else {
        if (videoRef.current.play) {
          videoRef.current.pause();
        }
      }
    }
  }, [isVisible]);
  useEffect(() => {
    (async () => {
      setLoading(true);
      const { data } = await Get(`api/v2/app/feeds/slug/${postSlug}`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      });
      if (data) {
        setFeed(data.feed);
      }
      setLoading(false);
    })();
  }, [token, setFeed, postSlug]);

  const likeHandler = async (_feed, _media) => {
    let url = `api/v2/app/feeds/like/${_feed._id}`;

    if (_media) {
      url = `api/v2/app/feeds/like/${_feed._id}/${_media._id}`;
    }

    const { data } = await Patch({}, url, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    });

    if (_feed && !_media) {
      if (data) {
        const newData = [];
        // setIsLikedByUser(feed.likes.includes(user._id.toString()));
        // console.log(isLikedByUser);
        if (feed.likes.includes(user._id.toString())) {
          if (feed._id.toString() === _feed._id.toString()) {
            const obj = { ..._feed };
            obj.likesCount = _feed.likesCount - 1;
            obj.likes = _feed.likes.filter(
              (userId) => user._id.toString() !== userId.toString()
            );
            newData.push(obj);
          } else {
            newData.push(_feed);
          }
        } else {
          if (feed._id.toString() === _feed._id.toString()) {
            const obj = { ..._feed };
            console.log(obj);
            obj.likesCount = obj.likesCount + 1;
            obj.likes.push(user._id);

            newData.push(obj);
          } else {
            newData.push(_feed);
          }
        }
        setFeed(newData[0]);
      }
    } else if (_feed && _media) {
      if (data) {
        // setIsLikedByUser(feed.likes.includes(user._id.toString()));
        // console.log(isLikedByUser);
        if (feed._id.toString() === _feed._id.toString()) {
          const newData = [];
          for (const media of feed.media) {
            if (media._id.toString() === _media._id.toString()) {
              if (
                media.likes.find((_user) => {
                  return _user.userId.toString() === user._id.toString();
                })
              ) {
                // console.log("running");
                const obj = { ...media };
                obj.likesCount = obj.likesCount - 1;
                obj.likes = obj.likes.filter(
                  (_user) => user._id.toString() !== _user.userId.toString()
                );
                newData.push(obj);
              } else {
                const obj = { ...media };
                obj.likesCount = obj.likesCount + 1;
                obj.likes.push({ userId: user._id });

                newData.push(obj);
              }
            } else {
              newData.push(media);
            }
          }
          const formattedFeed = { ...feed, media: newData };
          setFeed(formattedFeed);
        }
      }
    }
  };

  const commentEditHandler = async (comment, setContent) => {
    setEditingComment(true);
    setEditCurrentComment(comment);
    setContent(comment.content);
    // socket.emit("updateComment", { commentId, content });
  };

  const editReplyHandler = async (commentId, reply, setReply) => {
    setEditingReply(true);
    setEditCurrentReply(reply);
    setReply(reply.content);
  };

  // const editPostHandler = async (feedId) => {
  //   handleClickOpen();
  // };

  const addCommentHandler = async (
    feedId,
    content,
    setContent,
    setReply,
    commentId,
    mediaId
  ) => {
    if (content.trim().length > 0) {
      if (editingComment) {
        const values = {
          content,
          commentId: editCurrentComment._id,
        };
        socket.emit("updateComment", values);
        setEditingComment(false);
        setEditCurrentComment({});
      } else if (editingReply) {
        const values = {
          content,
          replyId: editCurrentReply._id,
          commentId,
        };
        socket.emit("updateReply", values);
        setEditingReply(false);
        setEditCurrentReply({});
      } else {
        const values = {
          feedId,
          content,
          userId: user._id,
        };
        if (commentId) values.commentId = commentId;
        if (mediaId) values.mediaId = mediaId;
        if (socket) {
          socket.emit("createComment", values);
        }
      }
      setContent("");
      setReply("");

      if (textRef && textRef.current) textRef.current.style.height = "42px";
      if (replyRef && replyRef.current) replyRef.current.style.height = "42px";
    }
  };

  useEffect(() => {
    if (socket) {
      socket.on("updateCommentResponse", (data) => {
        if (data && feed && feed._id) {
          const newData = [];
          if (data.updatedComment.feedId.toString() === feed._id.toString()) {
            // for (const comment of _feed.comments) {
            //   if (data.newComment._id.toString() === comment._id.toString()) {
            //     comment.replies = [...data.newComment.replies].reverse();
            //   }
            // }
            // console.log()
            const obj = { ...feed };
            const updatedComments = [];
            console.log(data.updatedComment);
            console.log(obj);
            if (data.updatedComment.mediaId) {
              for (const comment of obj.comments) {
                for (const media of comment.media) {
                  if (media._id.toString() === data.updatedComment.mediaId) {
                  }
                }
                if (
                  comment._id.toString() === data.updatedComment._id.toString()
                ) {
                  updatedComments.push(data.updatedComment);
                } else {
                  updatedComments.push(comment);
                }
              }
            } else {
              for (const comment of obj.comments) {
                console.log(comment);
                console.log(data.updatedComment);
                if (
                  comment._id.toString() === data.updatedComment._id.toString()
                ) {
                  updatedComments.push(data.updatedComment);
                } else {
                  updatedComments.push(comment);
                }
              }
            }

            console.log(updatedComments);

            obj.comments = [...updatedComments];

            console.log(obj);
            newData.push(obj);
          } else {
            newData.push(feed);
          }
          setFeed(newData[0]);
        }
      });
    }
  }, [socket, feed]);

  useEffect(() => {
    if (socket) {
      socket.on("updateReplyResponse", (data) => {
        if (data && feed && feed._id) {
          const newData = [];
          if (data.updatedComment.feedId.toString() === feed._id.toString()) {
            // for (const comment of _feed.comments) {
            //   if (data.newComment._id.toString() === comment._id.toString()) {
            //     comment.replies = [...data.newComment.replies].reverse();
            //   }
            // }
            const obj = { ...feed };

            const updatedComments = [];
            for (const comment of obj.comments) {
              if (
                comment._id.toString() === data.updatedComment._id.toString()
              ) {
                updatedComments.push(data.updatedComment);
              } else {
                updatedComments.push(comment);
              }
            }

            obj.comments = [...updatedComments];

            newData.push(obj);
          } else {
            newData.push(feed);
          }
          setFeed(newData[0]);
        }
      });
    }
  }, [socket, feed]);

  useEffect(() => {
    if (socket) {
      socket.on("getCommentResponse", (data) => {
        // console.log(data);
        // setComments((prevState) => [...prevState, data.newComment]);
        if (data && feed && feed._id) {
          // if(data.mediaId)
          if (!data.newComment.mediaId) {
            console.log(data);
            const newData = [];
            if (data.newComment.feedId.toString() === feed._id.toString()) {
              let isReply = false;
              for (const comment of feed.comments) {
                if (data.newComment._id.toString() === comment._id.toString()) {
                  isReply = true;

                  comment.replies = [...data.newComment.replies].reverse();
                }
              }
              if (!isReply) {
                feed.comments = [...feed.comments, data.newComment];
                feed.commentCount += 1;
              }
              const obj = { ...feed };
              newData.push(obj);
            } else {
              newData.push(feed);
            }
            setFeed(newData[0]);
          } else if (data.newComment.mediaId) {
            const newData = [];
            const obj = { ...feed };
            if (data.newComment.feedId.toString() === obj._id.toString()) {
              for (const media of obj.media) {
                if (
                  media._id.toString() === data.newComment.mediaId.toString()
                ) {
                  let isReply = false;
                  for (const comment of media.comments) {
                    if (
                      data.newComment._id.toString() === comment._id.toString()
                    ) {
                      isReply = true;
                      comment.replies = [...data.newComment.replies].reverse();
                    }
                  }
                  if (!isReply) {
                    media.comments = [...media.comments, data.newComment];
                    media.commentCount += 1;
                  }
                }
              }
              newData.push(obj);
            } else {
              newData.push(feed);
            }
            setFeed(newData[0]);
          }
        }
      });
    }
  }, [socket, feed]);

  const commentHandler = async (feedId, mediaId) => {
    let url = `api/v2/app/comment/${feedId}`;

    if (mediaId) {
      url = `api/v2/app/comment/${feedId}?mediaId=${mediaId}`;
    }
    const { data } = await Get(url, {
      headers: {
        "content-type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    });

    if (!mediaId) {
      const newData = [];
      if (feedId.toString() === feed._id.toString()) {
        const obj = { ...feed };
        obj.comments = [...data.comments];
        obj.commentShow = !obj.commentShow;
        newData.push(obj);
      } else {
        newData.push(feed);
      }
      setFeed(newData[0]);
    } else {
      const newData = [];
      if (feedId.toString() === feed._id.toString()) {
        const newMedia = [];
        const obj = { ...feed };

        for (const media of obj.media) {
          if (media._id.toString() === mediaId.toString()) {
            media.comments = [...data.comments];
            media.commentShow = !media.commentShow;
            newMedia.push(obj);
          } else {
            newMedia.push(obj);
          }
        }
      }
      newData.push(feed);
      setFeed({ ...newData[0] });
    }
  };

  const replyHandler = (feedId, commentId, mediaId, type) => {
    const newData = [];

    if (!mediaId) {
      if (feedId.toString() === feed._id.toString()) {
        const obj = { ...feed };
        // obj.commentShow = !obj.commentShow;
        // newData.push(obj);
        for (const _comment of obj.comments) {
          // if(comment.replies && comment)
          if (commentId.toString() === _comment._id.toString()) {
            // const obj = { ...feed };
            _comment.showReply = !_comment.showReply;
            // console.log(_comment);
          }
        }
        // console.log(obj);
        newData.push(obj);
      } else {
        newData.push(feed);
      }
    } else if (mediaId) {
      if (feedId.toString() === feed._id.toString()) {
        const obj = { ...feed };
        // obj.commentShow = !obj.commentShow;
        // newData.push(obj);
        const newMedia = [];
        for (const media of obj.media) {
          if (mediaId.toString() === media._id.toString()) {
            for (const _comment of media.comments) {
              // if(comment.replies && comment)
              if (commentId.toString() === _comment._id.toString()) {
                // const obj = { ...feed };
                if (type === "edit") {
                  _comment.showReply = true;
                } else {
                  _comment.showReply = !_comment.showReply;
                }
                // console.log(_comment);
              }
            }
            newMedia.push(media);
          } else {
            newMedia.push(media);
          }
        }
        obj.media = [...newMedia];

        // console.log(obj);
        newData.push(obj);
      } else {
        newData.push(feed);
      }
    }
    setFeed({ ...newData[0] });
  };
  // const deletePostHandler = async (id) => {
  //   const { status } = await Delete(`api/v2/app/feeds/${id}`, {
  //     headers: {
  //       "content-type": "application/json",
  //       Authorization: `Bearer ${token}`,
  //     },
  //   });
  //   if (status) {
  //     const newData = [...feeds];
  //     const formattedData = newData.filter(
  //       (post) => post._id.toString() !== id.toString()
  //     );
  //     setFeeds([...formattedData]);
  //   }
  // };

  const commentDeleteHandler = async (feedId, commentId) => {
    socket.emit("deleteComment", { commentId });
    // const { status } = await Delete(`api/v2/app/comment/${commentId}`, {
    //   headers: {
    //     "content-type": "application/json",
    //     Authorization: `Bearer ${token}`,
    //   },
    // });
    // if (status) {
    //   const newData = [...feeds];
    //   const formattedData = newData.filter(
    //     (post) => post._id.toString() !== id.toString()
    //   );
    //   setFeeds([...formattedData]);
    // }
  };

  useEffect(() => {
    // if(){}
    if (socket) {
      socket.on("deleteCommentResponse", (data) => {
        // setComments((prevState) => [...prevState, data.newComment]);
        console.log(data);
        if (feed && feed._id) {
          if (!data.deletedComment.mediaId) {
            const newData = [];
            if (data.deletedComment.feedId.toString() === feed._id.toString()) {
              // let isReply = false;
              // for (const comment of _feed.comments) {
              //   if (data.newComment._id.toString() === comment._id.toString()) {
              //     comment.replies = [...data.newComment.replies].reverse();
              //   }
              // }

              const obj = { ...feed };

              obj.comments = obj.comments.filter(
                (comment) => comment._id.toString() !== data.deletedComment._id
              );
              obj.commentCount -= 1;
              newData.push(obj);
            } else {
              newData.push(feed);
            }
            setFeed({ ...newData[0] });
          } else if (data.deletedComment.mediaId) {
            const newData = [];
            if (data.deletedComment.feedId.toString() === feed._id.toString()) {
              // let isReply = false;
              // for (const comment of _feed.comments) {
              //   if (data.newComment._id.toString() === comment._id.toString()) {
              //     comment.replies = [...data.newComment.replies].reverse();
              //   }
              // }

              const obj = { ...feed };

              const formattedMedia = [];
              for (let media of obj.media) {
                if (
                  media._id.toString() ===
                  data.deletedComment.mediaId.toString()
                ) {
                  media.comments = media.comments.filter(
                    (comment) =>
                      comment._id.toString() !== data.deletedComment._id
                  );

                  formattedMedia.push(media);
                } else {
                  formattedMedia.push(media);
                }
              }
              obj.media = [...formattedMedia];

              newData.push(obj);
            } else {
              newData.push(feed);
            }
            console.log(newData);
            setFeed({ ...newData[0] });
          }
        }
      });
    }
  }, [socket, feed]);

  const deleteReplyHandler = async (commentId, replyId) => {
    socket.emit("deleteReply", { commentId, replyId });
    // const { status } = await Delete(`api/v2/app/comment/${commentId}`, {
    //   headers: {
    //     "content-type": "application/json",
    //     Authorization: `Bearer ${token}`,
    //   },
    // });
    // if (status) {
    //   const newData = [...feeds];
    //   const formattedData = newData.filter(
    //     (post) => post._id.toString() !== id.toString()
    //   );
    //   setFeeds([...formattedData]);
    // }
  };

  useEffect(() => {
    if (socket) {
      socket.on("deleteReplyResponse", (data) => {
        console.log(data);
        if (data && feed && feed._id) {
          const newData = [];
          if (!data.findComment.mediaId) {
            if (data.findComment.feedId.toString() === feed._id.toString()) {
              // let isReply = false;
              // for (const comment of _feed.comments) {
              //   if (data.newComment._id.toString() === comment._id.toString()) {
              //     comment.replies = [...data.newComment.replies].reverse();
              //   }
              // }
              const obj = { ...feed };
              // obj.comments = obj.comments.filter(
              //   (comment) => comment._id.toString() !== data.deletedComment._id
              // );

              for (const _comment of obj.comments) {
                if (
                  _comment._id.toString() === data.findComment._id.toString()
                ) {
                  // const replies = [];
                  const tempReplies = [];
                  for (const reply of _comment.replies) {
                    for (const _reply of data.findComment.replies) {
                      if (reply._id.toString() === _reply._id.toString()) {
                        tempReplies.push(reply);
                      }
                    }
                  }
                  _comment.replies = [...tempReplies];
                }
              }

              newData.push(obj);
            } else {
              newData.push(feed);
            }
          } else {
            if (data.findComment.feedId.toString() === feed._id.toString()) {
              // let isReply = false;
              // for (const comment of _feed.comments) {
              //   if (data.newComment._id.toString() === comment._id.toString()) {
              //     comment.replies = [...data.newComment.replies].reverse();
              //   }
              // }
              const obj = { ...feed };
              // obj.comments = obj.comments.filter(
              //   (comment) => comment._id.toString() !== data.deletedComment._id
              // );
              for (const media of obj.media) {
                for (const _comment of media.comments) {
                  if (
                    _comment._id.toString() === data.findComment._id.toString()
                  ) {
                    // const replies = [];
                    const tempReplies = [];
                    for (const reply of _comment.replies) {
                      for (const _reply of data.findComment.replies) {
                        if (reply._id.toString() === _reply._id.toString()) {
                          tempReplies.push(reply);
                        }
                      }
                    }
                    _comment.replies = [...tempReplies];
                  }
                }
              }

              newData.push(obj);
            } else {
              newData.push(feed);
            }
          }
          setFeed({ ...newData[0] });
        }
      });
    }
  }, [socket, feed]);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const editPostClickHandler = async (feedId, currentState) => {
    if (currentState.title.trim().length > 0) {
      if (
        currentState.description.length > 0 &&
        currentState.description.trim().length === 0
      ) {
        toast.warn("Please enter valid description");
        return;
      }
      const { data } = await Patch(currentState, `api/v2/app/feeds/${feedId}`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      });

      const newData = [];
      if (feedId.toString() === feed._id.toString()) {
        const obj = { ...feed };
        obj.title = currentState.title;
        obj.description = currentState.description;
        newData.push(obj);
      } else {
        newData.push(feed);
      }
      setFeed(newData[0]);

      toast.success(data.message);
      setOpen(false);
    } else {
      toast.warn("Please enter valid title");
    }
  };

  const editPostHandler = async (feedId) => {
    handleClickOpen();
  };

  const navigate = useNavigate();

  // const deletePostHandler = async (id) => {
  //   const { status } = await Delete(`api/v2/app/feeds/${id}`, {
  //     headers: {
  //       "content-type": "application/json",
  //       Authorization: `Bearer ${token}`,
  //     },
  //   });
  //   if (status) {
  //     navigate("../", { replace: true });
  //   }
  // };

  const [isPlaying, setIsPlaying] = useState(false);
  const playPauseHandler = () => {
    console.log(videoRef);
    if (isPlaying) {
      videoRef.current.fullscreen();
    } else {
      videoRef.current.play();
    }
    setIsPlaying(!isPlaying);
  };

  // cons;

  console.log(user);
  console.log(feed);

  const onConfirmation = async () => {
    const { status } = await Delete(`api/v2/app/feeds/${feedId}`, {
      headers: {
        "content-type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    });
    setDeleteOpen(false);

    if (status) {
      navigate("../", { replace: true });
    }
  };

  const deletePostHandler = async (id) => {
    setFeedId(id);
    setDeleteOpen(true);
  };

  const changeHandler = () => {
    console.log("jdlsf");
  };

  console.log("running");

  return (
    <div className="container-relative">
      <SideBar />
      {loading ? (
        <Loader />
      ) : (
        <div className="in-container">
          <div className="post-wrap card">
            <div className="post-hd">
              <div className="post-profile">
                <figure className="avatar">
                  <Link to={`/${feed.userName}`}>
                    {feed && feed.userImage ? (
                      <img src={`${BASE_URL}${feed.userImage}`} alt="..." />
                    ) : (
                      <img src="/images/default.jpeg" alt="..." />
                    )}
                  </Link>
                </figure>
                <figcaption>
                  <div>
                    <h4>
                      <Link to={`/${feed.userName}`}>{feed.user}</Link>
                    </h4>
                    <span>{moment(feed.createdAt).fromNow()}</span>
                  </div>
                </figcaption>
                {user &&
                  feed &&
                  feed.userId &&
                  user._id &&
                  user._id.toString() === feed.userId.toString() && (
                    <div className="my-post-option">
                      <div className="dropdown">
                        <button
                          className="btn dropdown-toggle"
                          type="button"
                          id="dropdownMenuButton1"
                          data-bs-toggle="dropdown"
                          aria-expanded="false"
                        >
                          <img src="/images/dots-vertical.svg" />
                        </button>
                        <ul
                          className="dropdown-menu"
                          aria-labelledby="dropdownMenuButton1"
                        >
                          <li>
                            <button
                              className="dropdown-item"
                              onClick={() => editPostHandler(feed)}
                            >
                              <img src="/images/edit-03.svg" />
                              Edit Post
                            </button>
                          </li>
                          <li>
                            <button
                              className="dropdown-item"
                              onClick={() => deletePostHandler(feed._id)}
                              type="button"
                            >
                              <img src="/images/trash-03.svg" alt="..." />
                              Delete Post
                            </button>
                          </li>
                        </ul>
                      </div>
                    </div>
                  )}
              </div>
              <div className="post-description">
                <h4>{feed.title}</h4>
                <Linkify
                  componentDecorator={(decoratedHref, decoratedText, key) => (
                    <a target="blank" href={decoratedHref} key={key}>
                      {decoratedText}
                    </a>
                  )}
                >
                  {feed.description}
                </Linkify>
              </div>
            </div>
            <div className="post-images">
              <div className="slider-1">
                <div className="owl-carousel owl-theme">
                  <div className="item">
                    <a
                      data-fancybox="gallery"
                      href="https://images.pexels.com/photos/14296202/pexels-photo-14296202.jpeg"
                    >
                      <div className="post-images-in">
                        <img
                          src="https://images.pexels.com/photos/14296202/pexels-photo-14296202.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1"
                          alt="..."
                        />
                      </div>
                    </a>
                  </div>
                  <div className="item">
                    <a data-fancybox="gallery" href="assets/images/2.jpg">
                      <div className="post-images-in">
                        <img src="assets/images/2.jpg" />
                      </div>
                    </a>
                  </div>
                  <div className="item">
                    <a data-fancybox="gallery" href="assets/images/video.mp4">
                      <div className="post-images-in">
                        <video
                          controls
                          style={{ position: "relative" }}
                          controlsList="nodownload"
                        >
                          <source
                            src="assets/images/video.mp4"
                            type="video/mp4"
                          />
                          <source src="movie.ogg" type="video/ogg" />
                          Your browser does not support the video tag.
                          <div style={{ position: "absolute" }}>
                            <img src="/images/play.svg" />
                          </div>
                        </video>
                      </div>
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <PostActionBar
              feed={feed}
              likeHandler={likeHandler}
              commentHandler={commentHandler}
            />
            {feed.commentShow && (
              <Comments
                feedId={feed._id}
                image={user.image}
                addCommentHandler={addCommentHandler}
                comments={[...feed.comments].reverse()}
                replyHandler={replyHandler}
                commentDeleteHandler={commentDeleteHandler}
                deleteReplyHandler={deleteReplyHandler}
                commentEditHandler={commentEditHandler}
                editingComment={editingComment}
                editReplyHandler={editReplyHandler}
                textRef={textRef}
                replyRef={replyRef}
              />
            )}
          </div>

          {feed.media &&
            feed.media.length > 0 &&
            feed.media.map((media, index) => (
              <div className="post-wrap card" key={index}>
                <div className="post-images-in img-h-auto">
                  {media.type === "image" ? (
                    <img src={`${BASE_URL}${media.path}`} alt="..." />
                  ) : (
                    <VisibilitySensor
                      onChange={(isVisible) => setIsVisible(isVisible)}
                    >
                      <video
                        controls
                        controlsList="nodownload noremoteplayback"
                        ref={videoRef}
                        onclick={changeHandler}
                      >
                        <source src={`${BASE_URL}${media.path}`} />
                        Your browser does not support the video tag.
                      </video>
                    </VisibilitySensor>
                  )}

                  {/* {media.type !== "image" && (
                    <div
                      style={{
                        position: "absolute",
                        height: "80px",
                        top: "50%",
                        left: "50%",
                        transform: "translate(-50%, -50%)",
                      }}
                      onClick={playPauseHandler}
                    >
                      {isPlaying ? (
                        <img
                          style={{
                            height: "40px",
                            width: "40px",
                          }}
                          alt="..."
                          src="/images/pause.svg"
                        />
                      ) : (
                        <img
                          style={{
                            height: "40px",
                            width: "40px",
                          }}
                          alt="..."
                          src="/images/play.svg"
                        />
                      )}
                    </div>
                  )} */}
                </div>
                <PostActionBar
                  feed={feed}
                  likeHandler={likeHandler}
                  media={media}
                  commentHandler={commentHandler}
                />
                {media.commentShow && (
                  <Comments
                    feedId={feed._id}
                    mediaId={media._id}
                    image={user.image}
                    addCommentHandler={addCommentHandler}
                    comments={[...media.comments].reverse()}
                    replyHandler={replyHandler}
                    commentDeleteHandler={commentDeleteHandler}
                    deleteReplyHandler={deleteReplyHandler}
                    commentEditHandler={commentEditHandler}
                    editingComment={editingComment}
                    editReplyHandler={editReplyHandler}
                    textRef={textRef}
                    replyRef={replyRef}
                  />
                )}
              </div>
            ))}
          {feed && (
            <EditPostModal
              open={open}
              setOpen={setOpen}
              handleClickOpen={handleClickOpen}
              handleClose={handleClose}
              feed={feed}
              editPostClickHandler={editPostClickHandler}
            />
          )}
        </div>
      )}
      <AlertDialogSlide
        open={deleteOpen}
        setOpen={setDeleteOpen}
        deleteHandler={onConfirmation}
      >
        Are you Sure? You want to delete This Feed?
      </AlertDialogSlide>
    </div>
  );
};

export default PostDetail;
